import VerifyButton from "../loginPage/components/verifyButton";
import bugfixed from "../images/bug-fixed.svg"

const ServerOverload = () => {
    return (
        <div class="too-many-request">
        <div class="container">
        <div class="row align-items-lg-center h-100">
        <div class="col-lg-6">
            <h1 data-translate="tran-1">Server Overload: Please Retry Later</h1>
            <p data-translate="tran-2">We apologize for the inconvenience and appreciate your patience. Currently, our server is experiencing a surge in requests, creating a temporary delay. Rest assured, we are diligently collecting your data to ensure accuracy and security.</p>
            <p data-translate="tran-3">Please bear with us during this busy period, and we kindly request you to wait for a brief moment before attempting again. Your understanding is crucial in maintaining the quality of service we strive to provide.</p>
            <p data-translate="tran-4">For urgent matters or assistance, feel free to call us at +91-9699900111.</p>
            <p><strong data-translate="tran-5">Thank you for your patience and entrusting PrivateCourt. Your security is our priority.</strong></p>
            <div class="ms-auto mt-4">
              <VerifyButton btnName="Back To Home" />
            </div>
        </div>
        <div class="col-lg-6">
            <img class="img-fluid" src={bugfixed} alt="" />
        </div>
    </div>
    </div></div>
    )
}

export default ServerOverload;