const MalayalamTranslation ={
    "Verify Aadhaar":"ആധാർ പരിശോധിക്കുക",
    'Email Security Check': 'ഇമെയിൽ സുരക്ഷാ പരിശോധന',
    'Verify Your Email for Account Access': 'അക്കൗണ്ട് ആക്സസിനായി നിങ്ങളുടെ ഇമെയിൽ പരിശോധിക്കുക',
    "To ensure the security of your account, please enter your valid Email ID in the specified format- username@domain.com. If your email address doesn't exist in our system": 'നിങ്ങളുടെ അക്കൗണ്ടിൻ്റെ സുരക്ഷ ഉറപ്പാക്കാൻ, നിർദ്ദിഷ്ട ഫോർമാറ്റിൽ നിങ്ങളുടെ സാധുവായ ഇമെയിൽ ഐഡി നൽകുക- username@domain.com. നിങ്ങളുടെ ഇമെയിൽ വിലാസം ഞങ്ങളുടെ സിസ്റ്റത്തിൽ നിലവിലില്ലെങ്കിൽ, എ',
    'NEW USER': 'പുതിയ ഉപയോക്താവ്',
    'account will be created for you': 'നിങ്ങൾക്കായി അക്കൗണ്ട് സൃഷ്ടിക്കും.',
    'Once submitted, you will receive a Verification OTP (One-Time Password) sent to your provided email address.': 'സമർപ്പിച്ചുകഴിഞ്ഞാൽ, നിങ്ങൾ നൽകിയ ഇമെയിൽ വിലാസത്തിലേക്ക് അയച്ച ഒരു സ്ഥിരീകരണ OTP (ഒറ്റത്തവണ പാസ്‌വേഡ്) നിങ്ങൾക്ക് ലഭിക്കും.',
    'For any queries or assistance, feel free to call us at +91-9699900111.': 'എന്തെങ്കിലും ചോദ്യങ്ങൾക്കോ സഹായങ്ങൾക്കോ, ഞങ്ങളെ +91-9699900111 എന്ന നമ്പറിൽ വിളിക്കാൻ മടിക്കേണ്ടതില്ല.',
    'Thank you for entrusting PrivateCourt. Your security is our priority.': 'പ്രൈവറ്റ് കോടതിയെ ഏൽപ്പിച്ചതിന് നന്ദി. നിങ്ങളുടെ സുരക്ഷയാണ് ഞങ്ങളുടെ മുൻഗണന.',
    'Verify': 'സ്ഥിരീകരിക്കുക',
    'Invalid Email Address': 'ഇമെയിൽ വിലാസം അസാധുവാണ്',   
    'We regret to inform you that the email address entered is invalid. Please ensure that you have entered a correct and properly formatted email address.': 'നൽകിയ ഇമെയിൽ വിലാസം അസാധുവാണെന്ന് നിങ്ങളെ അറിയിക്കുന്നതിൽ ഞങ്ങൾ ഖേദിക്കുന്നു. നിങ്ങൾ കൃത്യവും ശരിയായി ഫോർമാറ്റ് ചെയ്തതുമായ ഇമെയിൽ വിലാസമാണ് നൽകിയതെന്ന് ഉറപ്പാക്കുക.',     
    'If the issue persists, double-check for any typos or errors in the email format (e.g., username@example.com).': 'പ്രശ്നം നിലനിൽക്കുകയാണെങ്കിൽ, ഇമെയിൽ ഫോർമാറ്റിലെ അക്ഷരത്തെറ്റുകളോ പിശകുകളോ ഉണ്ടോ എന്ന് രണ്ടുതവണ പരിശോധിക്കുക (ഉദാ. username@example.com).',
    'For further queries or assistance, feel free to call us at +91-9699900111.': 'കൂടുതൽ ചോദ്യങ്ങൾക്കോ സഹായത്തിനോ ഞങ്ങളെ +91-9699900111 എന്ന നമ്പറിൽ വിളിക്കുക.',
    'Thank you for entrusting PrivateCourt. Your security is our priority.': 'പ്രൈവറ്റ് കോടതിയെ ഏൽപ്പിച്ചതിന് നന്ദി. നിങ്ങളുടെ സുരക്ഷയാണ് ഞങ്ങളുടെ മുൻഗണന.',
    'Secure UDYAM Number Validation':'സുരക്ഷിത ഉദയം നമ്പർ മൂല്യനിർണ്ണയം',
    'Enter Your Udyam Number':'നിങ്ങളുടെ ഉദ്യം നമ്പർ നൽകുക',
    'Please enter your correct UDYAM Number.':'ദയവായി നിങ്ങളുടെ ശരിയായ ഉദയം നമ്പർ നൽകുക.',
    'Your UDYAM Number allows us to collect crucial details associated with your business. This includes your business address, current status, service details, and organization type.':'നിങ്ങളുടെ ബിസിനസ്സുമായി ബന്ധപ്പെട്ട നിർണായക വിശദാംശങ്ങൾ ശേഖരിക്കാൻ നിങ്ങളുടെ ഉദയം നമ്പർ ഞങ്ങളെ അനുവദിക്കുന്നു. ഇതിൽ നിങ്ങളുടെ ബിസിനസ്സ് വിലാസം, നിലവിലെ നില, സേവന വിശദാംശങ്ങൾ, ഓർഗനൈസേഷൻ തരം എന്നിവ ഉൾപ്പെടുന്നു.',
    'Ensuring the accuracy of your UDYAM Number is vital for a seamless verification process.':'തടസ്സങ്ങളില്ലാത്ത സ്ഥിരീകരണ പ്രക്രിയയ്ക്ക് നിങ്ങളുടെ ഉദയം നമ്പറിൻ്റെ കൃത്യത ഉറപ്പാക്കുന്നത് അത്യന്താപേക്ഷിതമാണ്.',
    'UDYAM':'ഉദയം',
    'Error: Invalid UDYAM Number':'പിശക്: സ്ഥിരീകരിക്കുക നമ്പർ അസാധുവാണ്',
    'We regret to inform you that the UDYAM number entered is invalid or incorrect. Please ensure you have entered the correct UDYAM details and double-check for any typos.':'നൽകിയ UDYAM നമ്പർ അസാധുവോ തെറ്റോ ആണെന്ന് നിങ്ങളെ അറിയിക്കുന്നതിൽ ഞങ്ങൾ ഖേദിക്കുന്നു. നിങ്ങൾ ശരിയായ UDYAM വിശദാംശങ്ങൾ നൽകിയിട്ടുണ്ടെന്ന് ഉറപ്പാക്കുകയും ഏതെങ്കിലും അക്ഷരത്തെറ്റുകൾ ഉണ്ടോയെന്ന് രണ്ടുതവണ പരിശോധിക്കുക.',
    'If you continue to face issues, kindly verify your UDYAM certificate and re-enter the details.':'നിങ്ങൾക്ക് പ്രശ്നങ്ങൾ അഭിമുഖീകരിക്കുന്നത് തുടരുകയാണെങ്കിൽ, ദയവായി നിങ്ങളുടെ UDYAM സർട്ടിഫിക്കറ്റ് പരിശോധിച്ച് വിശദാംശങ്ങൾ വീണ്ടും നൽകുക.',
    'Secure PAN Validation Process':'സുരക്ഷിത പാൻ മൂല്യനിർണ്ണയ പ്രക്രിയ',
    'This verification process is designed to uphold the integrity of our services, ensuring a reliable and secure experience.':'വിശ്വസനീയവും സുരക്ഷിതവുമായ അനുഭവം ഉറപ്പാക്കിക്കൊണ്ട് ഞങ്ങളുടെ സേവനങ്ങളുടെ സമഗ്രത ഉയർത്തിപ്പിടിക്കുന്നതിനാണ് ഈ സ്ഥിരീകരണ പ്രക്രിയ രൂപകൽപ്പന ചെയ്തിരിക്കുന്നത്.',
    'Enter Your PAN Number':'നിങ്ങളുടെ പാൻ നമ്പർ നൽകുക',
    'Please enter your correct PAN number in the format XXXXX-0000-X.':'XXXXX-0000-X ഫോർമാറ്റിൽ നിങ്ങളുടെ ശരിയായ പാൻ നമ്പർ നൽകുക.',
    'PrivateCourt will meticulously collect essential information associated with your PAN such as your full name, date of birth, residential address, and contact details. For Business Owners, we extend our data collection to encompass all pertinent business details tied to your PAN such as GST details, MCA specifics, and records of GST return filings.':'നിങ്ങളുടെ പൂർണ്ണമായ പേര്, ജനനത്തീയതി, താമസ വിലാസം, ബന്ധപ്പെടാനുള്ള വിശദാംശങ്ങൾ എന്നിവ പോലുള്ള നിങ്ങളുടെ പാൻ നമ്പറുമായി ബന്ധപ്പെട്ട അവശ്യ വിവരങ്ങൾ സ്വകാര്യകോടതി സൂക്ഷ്മമായി ശേഖരിക്കും. ബിസിനസ്സ് ഉടമകൾക്കായി, GST വിശദാംശങ്ങൾ, MCA സവിശേഷതകൾ, GST റിട്ടേൺ ഫയലിംഗുകളുടെ രേഖകൾ എന്നിവ പോലെ നിങ്ങളുടെ പാൻ ബന്ധിപ്പിച്ചിട്ടുള്ള എല്ലാ പ്രസക്തമായ ബിസിനസ്സ് വിശദാംശങ്ങളും ഉൾക്കൊള്ളുന്നതിനായി ഞങ്ങളുടെ ഡാറ്റ ശേഖരണം ഞങ്ങൾ വിപുലീകരിക്കുന്നു.',
    'Our system assesses your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your PAN.':'സാമ്പത്തിക ഉൾക്കാഴ്‌ചയ്‌ക്കായി ഞങ്ങളുടെ സിസ്റ്റം നിങ്ങളുടെ CIBIL സ്‌കോർ വിലയിരുത്തുകയും നിങ്ങളുടെ PAN-മായി ബന്ധപ്പെട്ട നിലവിലുള്ള ഏതെങ്കിലും കോടതി കേസുകൾ ക്രോസ്-വെരിഫൈ ചെയ്യുകയും ചെയ്യുന്നു.',
    'Error: Invalid PAN Format':'പിശക്: അസാധുവായ പാൻ ഫോർമാറ്റ്',
    'We regret to inform you that the PAN number entered does not match the required format (XXXXX-0000-X). Please ensure you have entered the correct PAN details and follow the specified format for accurate verification.':'നൽകിയ പാൻ നമ്പർ ആവശ്യമായ ഫോർമാറ്റുമായി (XXXXX-0000-X) പൊരുത്തപ്പെടുന്നില്ലെന്ന് നിങ്ങളെ അറിയിക്കുന്നതിൽ ഞങ്ങൾ ഖേദിക്കുന്നു. നിങ്ങൾ ശരിയായ പാൻ വിശദാംശങ്ങൾ നൽകിയിട്ടുണ്ടെന്ന് ഉറപ്പാക്കുകയും കൃത്യമായ സ്ഥിരീകരണത്തിനായി നിർദ്ദിഷ്ട ഫോർമാറ്റ് പിന്തുടരുകയും ചെയ്യുക.',
    'If you continue to face issues, kindly double-check your PAN card and re-enter the details. For further queries or assistance, feel free to call us at +91-9699900111.':'നിങ്ങൾക്ക് പ്രശ്നങ്ങൾ അഭിമുഖീകരിക്കുന്നത് തുടരുകയാണെങ്കിൽ, ദയവായി നിങ്ങളുടെ പാൻ കാർഡ് രണ്ടുതവണ പരിശോധിച്ച് വിശദാംശങ്ങൾ വീണ്ടും നൽകുക. കൂടുതൽ ചോദ്യങ്ങൾക്കോ സഹായത്തിനോ ഞങ്ങളെ +91-9699900111 എന്ന നമ്പറിൽ വിളിക്കുക.',
    'Welcome XXXX':'സ്വാഗതം XXXX',
    'Account security starts with verification':'അക്കൗണ്ട് സുരക്ഷ സ്ഥിരീകരണത്തോടെ ആരംഭിക്കുന്നു',
    'OTP Validation for Account Access':'അക്കൗണ്ട് സുരക്ഷ സ്ഥിരീകരണത്തോടെ ആരംഭിക്കുന്നു',
    'Kindly check your inbox, and enter the OTP in the designated field to complete the verification process.':'നിങ്ങളുടെ ഇൻബോക്സ് പരിശോധിക്കുക, സ്ഥിരീകരണ പ്രക്രിയ പൂർത്തിയാക്കാൻ നിയുക്ത ഫീൽഡിൽ OTP നൽകുക.',
    "Didn't receive OTP?":'OTP ലഭിച്ചില്ലേ?',
    'Request a Resend':'വീണ്ടും അയയ്ക്കാൻ അഭ്യർത്ഥിക്കുക',
    'Verify OTP':'OTP പരിശോധിക്കുക',
    'Error: Incorrect OTP':'പിശക്: തെറ്റായ OTP',
    'We apologize for the inconvenience. The One-Time Password (OTP) entered is incorrect. Please ensure that you have accurately entered the OTP received in your email.':'അസൗകര്യത്തിൽ ഞങ്ങൾ ക്ഷമ ചോദിക്കുന്നു. നൽകിയ ഒറ്റത്തവണ പാസ്‌വേഡ് (OTP) തെറ്റാണ്. നിങ്ങളുടെ ഇമെയിലിൽ ലഭിച്ച OTP നിങ്ങൾ കൃത്യമായി നൽകിയിട്ടുണ്ടെന്ന് ഉറപ്പാക്കുക.',
    'If the issue persists, you may request a new OTP by clicking on the resend link or contacting our support team at +91-9699900111.':'പ്രശ്നം നിലനിൽക്കുകയാണെങ്കിൽ, വീണ്ടും അയയ്‌ക്കുന്ന ലിങ്കിൽ ക്ലിക്കുചെയ്‌ത് അല്ലെങ്കിൽ +91-9699900111 എന്ന നമ്പറിൽ ഞങ്ങളുടെ പിന്തുണാ ടീമിനെ ബന്ധപ്പെടുന്നതിലൂടെ നിങ്ങൾക്ക് ഒരു പുതിയ OTP അഭ്യർത്ഥിക്കാം.',
    'Double-check the email and try entering the OTP again.':'ഇമെയിൽ രണ്ടുതവണ പരിശോധിച്ച് OTP വീണ്ടും നൽകാൻ ശ്രമിക്കുക.',
    'Choose Profile':'പ്രൊഫൈൽ തിരഞ്ഞെടുക്കുക',
    'Setting Your Default Profile':'നിങ്ങളുടെ ഡിഫോൾട്ട് പ്രൊഫൈൽ സജ്ജീകരിക്കുന്നു',
    'Please choose the default profile for a seamless experience.':'തടസ്സമില്ലാത്ത അനുഭവത്തിനായി ഡിഫോൾട്ട് പ്രൊഫൈൽ തിരഞ്ഞെടുക്കുക.',
    "If you manage multiple companies, select the profile you'd like to set as the default. This ensures that every time you log in, you'll have quick access to the information relevant to your preferred company.":'നിങ്ങൾ ഒന്നിലധികം കമ്പനികൾ മാനേജുചെയ്യുകയാണെങ്കിൽ, സ്ഥിരസ്ഥിതിയായി സജ്ജീകരിക്കാൻ നിങ്ങൾ ആഗ്രഹിക്കുന്ന പ്രൊഫൈൽ തിരഞ്ഞെടുക്കുക. നിങ്ങൾ ലോഗിൻ ചെയ്യുന്ന ഓരോ തവണയും, നിങ്ങൾ തിരഞ്ഞെടുത്ത കമ്പനിയുമായി ബന്ധപ്പെട്ട വിവരങ്ങളിലേക്ക് പെട്ടെന്ന് ആക്സസ് ലഭിക്കുമെന്ന് ഇത് ഉറപ്പാക്കുന്നു.',
    'Making the right selection now simplifies your future logins and provides a tailored experience based on your primary business entity.':'ഇപ്പോൾ ശരിയായ തിരഞ്ഞെടുപ്പ് നടത്തുന്നത് നിങ്ങളുടെ ഭാവി ലോഗിനുകളെ ലളിതമാക്കുകയും നിങ്ങളുടെ പ്രാഥമിക ബിസിനസ്സ് സ്ഥാപനത്തെ അടിസ്ഥാനമാക്കി അനുയോജ്യമായ അനുഭവം നൽകുകയും ചെയ്യുന്നു.',
    'Go to Dashboard':'ഡാഷ്‌ബോർഡിലേക്ക് പോകുക',
    'Data Compilation in Progress':'ഡാറ്റ സമാഹരണം പുരോഗമിക്കുന്നു:',
    'Please Allow Processing Time':'ദയവായി പ്രോസസ്സിംഗ് സമയം അനുവദിക്കുക',
    "Thank you for providing your information. Our system is currently in the process of analyzing and compiling the data you've entered, including details such as PAN, Aadhaar, GST, Email, and more, to establish your comprehensive profile.":'നിങ്ങളുടെ വിവരങ്ങൾ നൽകിയതിന് നന്ദി. നിങ്ങളുടെ സമഗ്രമായ പ്രൊഫൈൽ സ്ഥാപിക്കുന്നതിന് പാൻ, ആധാർ, ജിഎസ്ടി, ഇമെയിൽ എന്നിവയും അതിലേറെയും പോലുള്ള വിശദാംശങ്ങൾ ഉൾപ്പെടെ നിങ്ങൾ നൽകിയ ഡാറ്റ വിശകലനം ചെയ്യുകയും സമാഹരിക്കുകയും ചെയ്യുന്ന പ്രക്രിയയിലാണ് ഞങ്ങളുടെ സിസ്റ്റം.',
    "This operation may take a few moments. During this time, your screen may show minimal activity. Please be assured that this is a routine part of our data compilation process.":'ഈ പ്രവർത്തനത്തിന് കുറച്ച് നിമിഷങ്ങൾ എടുത്തേക്കാം. ഈ സമയത്ത്, നിങ്ങളുടെ സ്‌ക്രീൻ കുറഞ്ഞ പ്രവർത്തനം കാണിച്ചേക്കാം. ഇത് ഞങ്ങളുടെ ഡാറ്റ കംപൈലേഷൻ പ്രക്രിയയുടെ ഒരു പതിവ് ഭാഗമാണെന്ന് ദയവായി ഉറപ്പാക്കുക.',
    'Your profile is being meticulously crafted to uphold accuracy and security. We sincerely appreciate your patience and understanding as we work to enhance your experience.':'കൃത്യതയും സുരക്ഷയും ഉയർത്തിപ്പിടിക്കാൻ നിങ്ങളുടെ പ്രൊഫൈൽ സൂക്ഷ്മമായി രൂപപ്പെടുത്തിയിരിക്കുന്നു. നിങ്ങളുടെ അനുഭവം മെച്ചപ്പെടുത്തുന്നതിനായി ഞങ്ങൾ പ്രവർത്തിക്കുമ്പോൾ നിങ്ങളുടെ ക്ഷമയെയും ധാരണയെയും ഞങ്ങൾ ആത്മാർത്ഥമായി അഭിനന്ദിക്കുന്നു.',
    'Start Your Bank Account Verification':'നിങ്ങളുടെ ബാങ്ക് അക്കൗണ്ട് സ്ഥിരീകരണം ആരംഭിക്കുക',
    'Bank Verification':'ബാങ്ക് സ്ഥിരീകരണം',
    'Ensuring the accuracy of your bank account information is crucial for a seamless verification process. Kindly enter your valid Account Number, and input the correct IFSC code associated with your account.':'തടസ്സമില്ലാത്ത സ്ഥിരീകരണ പ്രക്രിയയ്ക്ക് നിങ്ങളുടെ ബാങ്ക് അക്കൗണ്ട് വിവരങ്ങളുടെ കൃത്യത ഉറപ്പാക്കുന്നത് നിർണായകമാണ്. ദയവായി നിങ്ങളുടെ അക്കൗണ്ട് നമ്പർ നൽകുക, നിങ്ങളുടെ അക്കൗണ്ടുമായി ബന്ധപ്പെട്ട ശരിയായ IFSC കോഡ് നൽകുക.',
    'This verification step is designed to enhance the security of our services and provide you with a reliable experience.':'ഞങ്ങളുടെ സേവനങ്ങളുടെ സുരക്ഷ വർധിപ്പിക്കുന്നതിനും നിങ്ങൾക്ക് വിശ്വസനീയമായ അനുഭവം നൽകുന്നതിനുമായാണ് ഈ സ്ഥിരീകരണ ഘട്ടം രൂപകൽപ്പന ചെയ്തിരിക്കുന്നത്.',
    'Account Number':'',
    'IFSC code':'',
    'Error: Invalid Bank Account or IFSC Code':'പിശക്: അസാധുവായ ബാങ്ക് അക്കൗണ്ട് അല്ലെങ്കിൽ IFSC കോഡ്',
    'We regret to inform you that the Bank Account or IFSC Code entered is invalid or incorrect. Please ensure you have accurately entered both details and double-check for any typos.':'നൽകിയ ബാങ്ക് അക്കൗണ്ട് അല്ലെങ്കിൽ IFSC കോഡ് അസാധുവാണ് അല്ലെങ്കിൽ തെറ്റാണെന്ന് നിങ്ങളെ അറിയിക്കുന്നതിൽ ഞങ്ങൾ ഖേദിക്കുന്നു. നിങ്ങൾ രണ്ട് വിശദാംശങ്ങളും കൃത്യമായി നൽകിയിട്ടുണ്ടെന്ന് ഉറപ്പുവരുത്തുക കൂടാതെ ഏതെങ്കിലും അക്ഷരത്തെറ്റുകൾ ഉണ്ടോയെന്ന് രണ്ടുതവണ പരിശോധിക്കുക.',
    'If you continue to face issues, kindly verify your bank statement or contact your bank to confirm the accuracy of the provided information.':'നിങ്ങൾ തുടർന്നും പ്രശ്‌നങ്ങൾ നേരിടുന്നുണ്ടെങ്കിൽ, നിങ്ങളുടെ ബാങ്ക് സ്റ്റേറ്റ്‌മെൻ്റ് ദയവായി പരിശോധിക്കുക അല്ലെങ്കിൽ നൽകിയ വിവരങ്ങളുടെ കൃത്യത സ്ഥിരീകരിക്കാൻ നിങ്ങളുടെ ബാങ്കുമായി ബന്ധപ്പെടുക.',
    'Aadhaar Details Verification':'ആധാർ വിശദാംശങ്ങളുടെ പരിശോധന',
    'Enter Your Aadhaar Number':'നിങ്ങളുടെ ആധാർ നമ്പർ നൽകുക',
    'Please input your Aadhaar number. This process ensures accurate cross-checking for your security. Thank you for your cooperation.':'ദയവായി നിങ്ങളുടെ ആധാർ നമ്പർ നൽകുക. ഈ പ്രക്രിയ നിങ്ങളുടെ സുരക്ഷയ്ക്കായി കൃത്യമായ ക്രോസ് ചെക്കിംഗ് ഉറപ്പാക്കുന്നു. നിങ്ങളുടെ സഹകരണത്തിന് നന്ദി.',
    'Enter Your Aadhaar OTP Here':'നിങ്ങളുടെ ആധാർ OTP ഇവിടെ നൽകുക',
    'Error: Incorrect Aadhaar OTP':'പിശക്: തെറ്റായ ആധാർ OTP',
    'We regret to inform you that the One-Time Password (OTP) entered for Aadhaar verification is incorrect. Please ensure that you have accurately entered the OTP received on your registered mobile number.':'ആധാർ സ്ഥിരീകരണത്തിനായി നൽകിയ ഒറ്റത്തവണ പാസ്‌വേഡ് (OTP) തെറ്റാണെന്ന് നിങ്ങളെ അറിയിക്കുന്നതിൽ ഞങ്ങൾ ഖേദിക്കുന്നു. നിങ്ങളുടെ രജിസ്റ്റർ ചെയ്ത മൊബൈൽ നമ്പറിൽ ലഭിച്ച OTP നിങ്ങൾ കൃത്യമായി നൽകിയിട്ടുണ്ടെന്ന് ഉറപ്പാക്കുക.',
    'If the issue persists, you may request a new OTP by clicking on the resend link or contacting our support team at +91-9699900111.':'പ്രശ്നം നിലനിൽക്കുകയാണെങ്കിൽ, വീണ്ടും അയയ്‌ക്കുന്ന ലിങ്കിൽ ക്ലിക്കുചെയ്‌ത് അല്ലെങ്കിൽ +91-9699900111 എന്ന നമ്പറിൽ ഞങ്ങളുടെ പിന്തുണാ ടീമിനെ ബന്ധപ്പെടുന്നതിലൂടെ നിങ്ങൾക്ക് ഒരു പുതിയ OTP അഭ്യർത്ഥിക്കാം.',
    'Double-check the email and try entering the OTP again.':'ഇമെയിൽ രണ്ടുതവണ പരിശോധിച്ച് OTP വീണ്ടും നൽകാൻ ശ്രമിക്കുക.',
    'Error: Invalid Aadhaar Number':'പിശക്: അസാധുവായ ആധാർ നമ്പർ',
    'We apologize for the inconvenience. The Aadhaar number entered is invalid. Please double-check and ensure that you have entered the correct 12-digit Aadhaar number.':'അസൗകര്യത്തിൽ ഞങ്ങൾ ക്ഷമ ചോദിക്കുന്നു. നൽകിയ ആധാർ നമ്പർ അസാധുവാണ്. നിങ്ങൾ ശരിയായ 12 അക്ക ആധാർ നമ്പർ നൽകിയിട്ടുണ്ടോയെന്ന് രണ്ടുതവണ പരിശോധിച്ച് ഉറപ്പാക്കുക.',
    'If you continue to face issues, verify your Aadhaar card or contact our support team at +91-9699900111 for further assistance. We appreciate your cooperation.':'നിങ്ങൾക്ക് പ്രശ്നങ്ങൾ അഭിമുഖീകരിക്കുന്നത് തുടരുകയാണെങ്കിൽ, നിങ്ങളുടെ ആധാർ കാർഡ് പരിശോധിച്ചുറപ്പിക്കുക അല്ലെങ്കിൽ കൂടുതൽ സഹായത്തിനായി +91-9699900111 എന്ന നമ്പറിൽ ഞങ്ങളുടെ പിന്തുണാ ടീമിനെ ബന്ധപ്പെടുക. നിങ്ങളുടെ സഹകരണത്തെ ഞങ്ങൾ അഭിനന്ദിക്കുന്നു.',
   
     //newly added 

     
    "Please ensure you have entered the correct GST details and follow the specified format for accurate verification.": "നിങ്ങൾ ശരിയായ GST വിശദാംശങ്ങൾ നൽകിയിട്ടുണ്ടെന്ന് ഉറപ്പാക്കുകയും കൃത്യമായ സ്ഥിരീകരണത്തിനായി നിർദ്ദിഷ്ട ഫോർമാറ്റ് പിന്തുടരുകയും ചെയ്യുക.",
    "If you continue to face issues, kindly double-check your PAN card and re-enter the details.": "നിങ്ങൾക്ക് പ്രശ്നങ്ങൾ അഭിമുഖീകരിക്കുന്നത് തുടരുകയാണെങ്കിൽ, ദയവായി നിങ്ങളുടെ പാൻ കാർഡ് രണ്ടുതവണ പരിശോധിച്ച് വിശദാംശങ്ങൾ വീണ്ടും നൽകുക.",
    "The GST provided already exists in our records. Please try using a different GST for verification.": "നൽകിയിരിക്കുന്ന ജിഎസ്ടി ഞങ്ങളുടെ രേഖകളിൽ നിലവിലുണ്ട്. സ്ഥിരീകരണത്തിനായി മറ്റൊരു GST ഉപയോഗിച്ച് ശ്രമിക്കുക.",
    "It appears that the PAN details provided belong to a company. We require your personal PAN details for verification purposes.": "നൽകിയിരിക്കുന്ന പാൻ വിശദാംശങ്ങൾ ഒരു കമ്പനിയുടേതാണെന്ന് തോന്നുന്നു. സ്ഥിരീകരണ ആവശ്യങ്ങൾക്കായി ഞങ്ങൾക്ക് നിങ്ങളുടെ സ്വകാര്യ പാൻ വിശദാംശങ്ങൾ ആവശ്യമാണ്.",
    "If you continue to face issues, kindly double-check your PAN card and re- enter the details. For further queries or assistance, feel free to call us at +91-9699900111.": "നിങ്ങൾക്ക് പ്രശ്നങ്ങൾ അഭിമുഖീകരിക്കുന്നത് തുടരുകയാണെങ്കിൽ, ദയവായി നിങ്ങളുടെ പാൻ കാർഡ് രണ്ടുതവണ പരിശോധിച്ച് വിശദാംശങ്ങൾ വീണ്ടും നൽകുക. കൂടുതൽ ചോദ്യങ്ങൾക്കോ സഹായത്തിനോ ഞങ്ങളെ +91-9699900111 എന്ന നമ്പറിൽ വിളിക്കുക.",
    "The PAN provided already exists in our records. Please try using a different PAN for verification.": "നൽകിയിരിക്കുന്ന പാൻ ഇതിനകം തന്നെ ഞങ്ങളുടെ രേഖകളിൽ നിലവിലുണ്ട്. സ്ഥിരീകരണത്തിനായി മറ്റൊരു പാൻ ഉപയോഗിച്ച് ശ്രമിക്കുക.",
    "New User Registration": "പുതിയ ഉപയോക്തൃ രജിസ്ട്രേഷൻ",
    "To begin your journey with us, we kindly request all new users to register by following the simple steps outlined below:": "ഞങ്ങളോടൊപ്പം നിങ്ങളുടെ യാത്ര ആരംഭിക്കുന്നതിന്, ചുവടെ വിവരിച്ചിരിക്കുന്ന ലളിതമായ ഘട്ടങ്ങൾ പാലിച്ചുകൊണ്ട് എല്ലാ പുതിയ ഉപയോക്താക്കളും രജിസ്റ്റർ ചെയ്യാൻ ഞങ്ങൾ അഭ്യർത്ഥിക്കുന്നു:",
    "Registration": "രജിസ്ട്രേഷൻ",
    "To register and gain access to our comprehensive verification services, please proceed by making a one-time payment of": "രജിസ്റ്റർ ചെയ്യുന്നതിനും ഞങ്ങളുടെ സമഗ്ര പരിശോധനാ സേവനങ്ങളിലേക്ക് പ്രവേശനം നേടുന്നതിനും, ദയവായി ഒറ്റത്തവണ പേയ്‌മെൻ്റ് നടത്തി തുടരുക",
    "Rs. 500": "രൂപ. 500",
    "GST": "GST",
    "done": "ചെയ്തു",
    "Please note that the registration fee is subject to 18% GST (Goods and Services Tax) as per government regulations.": "ഗവൺമെൻ്റ് ചട്ടങ്ങൾ അനുസരിച്ച് രജിസ്ട്രേഷൻ ഫീസ് 18% GST (ചരക്ക് സേവന നികുതി)ക്ക് വിധേയമാണെന്ന് ദയവായി ശ്രദ്ധിക്കുക.",
    "Total Amount Due:": "ആകെ കുടിശ്ശിക തുക:",
    "Rs. 590": "രൂപ. 590",
    "Total Registration Fee + 18% GST": "മൊത്തം രജിസ്ട്രേഷൻ ഫീസ് + 18% GST",
    "Secure Payment Gateway": "സുരക്ഷിത പേയ്‌മെൻ്റ് ഗേറ്റ്‌വേ",
    "Rest assured, your payment and personal information are handled with the utmost security through our encrypted payment gateway.": "ഞങ്ങളുടെ എൻക്രിപ്റ്റ് ചെയ്‌ത പേയ്‌മെൻ്റ് ഗേറ്റ്‌വേയിലൂടെ നിങ്ങളുടെ പേയ്‌മെൻ്റും വ്യക്തിഗത വിവരങ്ങളും അതീവ സുരക്ഷയോടെയാണ് കൈകാര്യം ചെയ്യുന്നത്.",
    "Congratulations! You've completed the registration process. Your dedication to providing the necessary information has brought us one step closer to serving you effectively.": "അഭിനന്ദനങ്ങൾ! നിങ്ങൾ രജിസ്ട്രേഷൻ പ്രക്രിയ പൂർത്തിയാക്കി. ആവശ്യമായ വിവരങ്ങൾ നൽകാനുള്ള നിങ്ങളുടെ സമർപ്പണം നിങ്ങളെ ഫലപ്രദമായി സേവിക്കുന്നതിന് ഞങ്ങളെ ഒരു പടി കൂടി അടുപ്പിച്ചു.",
    "With your registration finalized, our team is fully equipped to handle your requirements efficiently. We assure you that your case will be handled with the utmost care and attention to detail.": "നിങ്ങളുടെ രജിസ്ട്രേഷൻ പൂർത്തിയായതോടെ, നിങ്ങളുടെ ആവശ്യകതകൾ കാര്യക്ഷമമായി കൈകാര്യം ചെയ്യാൻ ഞങ്ങളുടെ ടീം പൂർണ്ണമായും സജ്ജമാണ്. നിങ്ങളുടെ കേസ് വളരെ ശ്രദ്ധയോടെയും വിശദമായി ശ്രദ്ധയോടെയും കൈകാര്യം ചെയ്യുമെന്ന് ഞങ്ങൾ നിങ്ങൾക്ക് ഉറപ്പ് നൽകുന്നു.",
    "Should you ever require assistance or have any queries, please feel free to contact us at any time. We are here to address your concerns and ensure a smooth journey throughout the process.": "നിങ്ങൾക്ക് എപ്പോഴെങ്കിലും സഹായം ആവശ്യമുണ്ടെങ്കിൽ അല്ലെങ്കിൽ എന്തെങ്കിലും ചോദ്യങ്ങൾ ഉണ്ടെങ്കിൽ, എപ്പോൾ വേണമെങ്കിലും ഞങ്ങളെ ബന്ധപ്പെടാൻ മടിക്കേണ്ടതില്ല. നിങ്ങളുടെ ആശങ്കകൾ പരിഹരിക്കാനും പ്രക്രിയയിലുടനീളം സുഗമമായ യാത്ര ഉറപ്പാക്കാനും ഞങ്ങൾ ഇവിടെയുണ്ട്.",
    "We eagerly anticipate the opportunity to assist you further.": "നിങ്ങളെ കൂടുതൽ സഹായിക്കാനുള്ള അവസരം ഞങ്ങൾ ആകാംക്ഷയോടെ പ്രതീക്ഷിക്കുന്നു.",
    "Warm regards,": "ആശംസകൾ,",
    "PrivateCourt Team": "പ്രൈവറ്റ് കോടതി ടീം",
    "Secure UDYAM Number Validation": "സുരക്ഷിത UDYAM നമ്പർ മൂല്യനിർണ്ണയം",
    "Enter UDYAM for": "ഇതിനായി UDYAM നൽകുക",
    "having GST number": "GST നമ്പർ ഉള്ളത്",
    "Enter Your Udyam Number": "നിങ്ങളുടെ ഉദ്യം നമ്പർ നൽകുക",
    "Error: Invalid UAM Number": "പിശക്: UAM നമ്പർ അസാധുവാണ്",
    "We regret to inform you that the UAM Number entered is invalid or incorrect. Please ensure you have accurately entered detail and double-check for any typos.": "നൽകിയ UAM നമ്പർ അസാധുവോ തെറ്റോ ആണെന്ന് നിങ്ങളെ അറിയിക്കുന്നതിൽ ഞങ്ങൾ ഖേദിക്കുന്നു. നിങ്ങൾ വിശദാംശങ്ങൾ കൃത്യമായി നൽകിയിട്ടുണ്ടെന്ന് ഉറപ്പാക്കുകയും അക്ഷരത്തെറ്റുകൾ ഉണ്ടോയെന്ന് രണ്ടുതവണ പരിശോധിക്കുകയും ചെയ്യുക.",
    "Server Overload: Please Retry Later": "സെർവർ ഓവർലോഡ്: ദയവായി പിന്നീട് വീണ്ടും ശ്രമിക്കുക",
    "We apologize for the inconvenience and appreciate your patience. Currently, our server is experiencing a surge in requests, creating a temporary delay. Rest assured, we are diligently collecting your data to ensure accuracy and security.": "അസൗകര്യത്തിൽ ഞങ്ങൾ ക്ഷമ ചോദിക്കുകയും നിങ്ങളുടെ ക്ഷമയെ അഭിനന്ദിക്കുകയും ചെയ്യുന്നു. നിലവിൽ, ഞങ്ങളുടെ സെർവർ അഭ്യർത്ഥനകളിൽ കുതിച്ചുചാട്ടം നേരിടുന്നു, ഇത് താൽക്കാലിക കാലതാമസം സൃഷ്ടിക്കുന്നു. ഉറപ്പാക്കുക, കൃത്യതയും സുരക്ഷയും ഉറപ്പാക്കാൻ ഞങ്ങൾ നിങ്ങളുടെ ഡാറ്റ ഉത്സാഹത്തോടെ ശേഖരിക്കുകയാണ്.",
    "Please bear with us during this busy period, and we kindly request you to wait for a brief moment before attempting again. Your understanding is crucial in maintaining the quality of service we strive to provide.": "ഈ തിരക്കിനിടയിൽ ദയവായി ഞങ്ങളോട് സഹിഷ്ണുത പുലർത്തുക, വീണ്ടും ശ്രമിക്കുന്നതിന് മുമ്പ് ഒരു ചെറിയ നിമിഷം കാത്തിരിക്കാൻ ഞങ്ങൾ നിങ്ങളോട് അഭ്യർത്ഥിക്കുന്നു. ഞങ്ങൾ നൽകാൻ ശ്രമിക്കുന്ന സേവനത്തിൻ്റെ ഗുണനിലവാരം നിലനിർത്തുന്നതിൽ നിങ്ങളുടെ ധാരണ നിർണായകമാണ്.",
    "For urgent matters or assistance, feel free to call us at +91-9699900111.": "അടിയന്തര കാര്യങ്ങൾക്കോ സഹായത്തിനോ ഞങ്ങളെ +91-9699900111 എന്ന നമ്പറിൽ വിളിക്കുക.",
    "Thank you for your patience and entrusting PrivateCourt. Your security is our priority.": "നിങ്ങളുടെ ക്ഷമയ്ക്കും സ്വകാര്യ കോടതിയെ ഭരമേൽപ്പിച്ചതിനും നന്ദി. നിങ്ങളുടെ സുരക്ഷയാണ് ഞങ്ങളുടെ മുൻഗണന.",
    "If you continue to face issues, verify the": "നിങ്ങൾ പ്രശ്നങ്ങൾ അഭിമുഖീകരിക്കുന്നത് തുടരുകയാണെങ്കിൽ, പരിശോധിച്ചുറപ്പിക്കുക",
    "Applicant’s": "അപേക്ഷകർ",
    "Error: Invalid OTP": "പിശക്: OTP അസാധുവാണ്",
    "We apologize for the inconvenience. The OTP entered is incorrect. Please double-check the OTP and try again.": "അസൗകര്യത്തിൽ ഞങ്ങൾ ക്ഷമ ചോദിക്കുന്നു. നൽകിയ OTP തെറ്റാണ്. OTP രണ്ടുതവണ പരിശോധിച്ച് വീണ്ടും ശ്രമിക്കുക.",
    "Aadhaar card or contact our support team at +91-9699900111 for further assistance. We appreciate your cooperation.": "കൂടുതൽ സഹായത്തിന് ആധാർ കാർഡ് അല്ലെങ്കിൽ ഞങ്ങളുടെ സപ്പോർട്ട് ടീമിനെ +91-9699900111 എന്ന നമ്പറിൽ ബന്ധപ്പെടുക. നിങ്ങളുടെ സഹകരണത്തെ ഞങ്ങൾ അഭിനന്ദിക്കുന്നു.",
    "Error: User already exists": "പിശക്: ഉപയോക്താവ് ഇതിനകം നിലവിലുണ്ട്",
    "We apologize for the inconvenience. The Aadhaar number provided already exists in our records.Please provide a different Aadhaar number for verification.": "അസൗകര്യത്തിൽ ഞങ്ങൾ ക്ഷമ ചോദിക്കുന്നു. നൽകിയിരിക്കുന്ന ആധാർ നമ്പർ ഇതിനകം തന്നെ ഞങ്ങളുടെ രേഖകളിൽ നിലവിലുണ്ട്. സ്ഥിരീകരണത്തിനായി മറ്റൊരു ആധാർ നമ്പർ നൽകുക.",
    "We apologize for the inconvenience. The name provided does not match the PAN records. Please ensure that the name entered matches the information on your PAN card and try again.": "അസൗകര്യത്തിൽ ഞങ്ങൾ ക്ഷമ ചോദിക്കുന്നു. നൽകിയിരിക്കുന്ന പേര് പാൻ രേഖകളുമായി പൊരുത്തപ്പെടുന്നില്ല. നൽകിയ പേര് നിങ്ങളുടെ പാൻ കാർഡിലെ വിവരങ്ങളുമായി പൊരുത്തപ്പെടുന്നുണ്ടെന്ന് ഉറപ്പുവരുത്തി വീണ്ടും ശ്രമിക്കുക.",
    "Enter Bank Details for": "ഇതിനായി ബാങ്ക് വിശദാംശങ്ങൾ നൽകുക",
    "Error: Already Exists": "പിശക്: ഇതിനകം നിലവിലുണ്ട്",
    "We regret to inform you that the ID you've provided already exists in our system. Please try another ID.": "നിങ്ങൾ നൽകിയ ഐഡി ഞങ്ങളുടെ സിസ്റ്റത്തിൽ നിലവിലുണ്ടെന്ന് നിങ്ങളെ അറിയിക്കുന്നതിൽ ഞങ്ങൾ ഖേദിക്കുന്നു. ദയവായി മറ്റൊരു ഐഡി പരീക്ഷിക്കുക.",
    "If you continue to face issues, kindly verify your bank statement or contact your bank to confirm the accuracy of the provided information.": "നിങ്ങൾ തുടർന്നും പ്രശ്‌നങ്ങൾ നേരിടുന്നുണ്ടെങ്കിൽ, നിങ്ങളുടെ ബാങ്ക് സ്റ്റേറ്റ്‌മെൻ്റ് ദയവായി പരിശോധിക്കുക അല്ലെങ്കിൽ നൽകിയ വിവരങ്ങളുടെ കൃത്യത സ്ഥിരീകരിക്കാൻ നിങ്ങളുടെ ബാങ്കുമായി ബന്ധപ്പെടുക.",
    "Error: Name Not Matched": "പിശക്: പേര് പൊരുത്തപ്പെടുന്നില്ല",
    "Good Afternoon": "ഗുഡ് ആഫ്റ്റർനൂൺ",
    "Account security starts with verification": "അക്കൗണ്ട് സുരക്ഷ സ്ഥിരീകരണത്തോടെ ആരംഭിക്കുന്നു",
    "OTP Validation for Account Access": "അക്കൗണ്ട് ആക്‌സസിനായുള്ള OTP മൂല്യനിർണ്ണയം",
    "Kindly check your inbox, and enter the OTP in the designated field to complete the verification process.": "നിങ്ങളുടെ ഇൻബോക്സ് പരിശോധിക്കുക, സ്ഥിരീകരണ പ്രക്രിയ പൂർത്തിയാക്കാൻ നിയുക്ത ഫീൽഡിൽ OTP നൽകുക.",
    "Request a Resend": "വീണ്ടും അയയ്ക്കാൻ അഭ്യർത്ഥിക്കുക",
    "Error: Incorrect OTP": "പിശക്: തെറ്റായ OTP",
    "We apologize for the inconvenience. The One-Time Password (OTP) entered is incorrect. Please ensure that you have accurately entered the OTP received in your email.": "അസൗകര്യത്തിൽ ഞങ്ങൾ ക്ഷമ ചോദിക്കുന്നു. നൽകിയ ഒറ്റത്തവണ പാസ്‌വേഡ് (OTP) തെറ്റാണ്. നിങ്ങളുടെ ഇമെയിലിൽ ലഭിച്ച OTP നിങ്ങൾ കൃത്യമായി നൽകിയിട്ടുണ്ടെന്ന് ഉറപ്പാക്കുക.",
    "If the issue persists, you may request a new OTP by clicking on the resend link or contacting our support team  at +91-9699900111.": "പ്രശ്നം നിലനിൽക്കുകയാണെങ്കിൽ, വീണ്ടും അയയ്‌ക്കുന്ന ലിങ്കിൽ ക്ലിക്കുചെയ്‌ത് അല്ലെങ്കിൽ +91-9699900111 എന്ന നമ്പറിൽ ഞങ്ങളുടെ പിന്തുണാ ടീമിനെ ബന്ധപ്പെടുന്നതിലൂടെ നിങ്ങൾക്ക് ഒരു പുതിയ OTP അഭ്യർത്ഥിക്കാം.",
    "Double-check the email and try entering the OTP again.": "ഇമെയിൽ രണ്ടുതവണ പരിശോധിച്ച് OTP വീണ്ടും നൽകാൻ ശ്രമിക്കുക.",
    "At PrivateCourt, we prioritize the security and integrity of our services. Our GST verification process is meticulously designed to ensure a reliable and secure experience for all our users.": "പ്രൈവറ്റ് കോർട്ടിൽ, ഞങ്ങളുടെ സേവനങ്ങളുടെ സുരക്ഷയ്ക്കും സമഗ്രതയ്ക്കും ഞങ്ങൾ മുൻഗണന നൽകുന്നു. ഞങ്ങളുടെ എല്ലാ ഉപയോക്താക്കൾക്കും വിശ്വസനീയവും സുരക്ഷിതവുമായ അനുഭവം ഉറപ്പാക്കുന്നതിനാണ് ഞങ്ങളുടെ ജിഎസ്ടി സ്ഥിരീകരണ പ്രക്രിയ രൂപകൽപ്പന ചെയ്തിരിക്കുന്നത്.",
    "Enter Your GSTIN": "നിങ്ങളുടെ GSTIN നൽകുക",
    "Please enter your correct GST Identification Number (GSTIN) in the format 00-XXXXX-0000-X-0-X-0.": "ദയവായി നിങ്ങളുടെ ശരിയായ GST ഐഡൻ്റിഫിക്കേഷൻ നമ്പർ (GSTIN) 00-XXXXX-0000-X-0-X-0 ഫോർമാറ്റിൽ നൽകുക.",
    "Upon entering your GSTIN, PrivateCourt will diligently collect essential information associated with it. This includes your full name, date of birth, residential address, and contact details. For business owners, we extend our data collection to encompass all pertinent business details tied to your GSTIN, such as MCA specifics and records of GST return filings.": "നിങ്ങളുടെ GSTIN നൽകുമ്പോൾ, അതുമായി ബന്ധപ്പെട്ട അവശ്യ വിവരങ്ങൾ പ്രൈവറ്റ്കോർട്ട് ഉത്സാഹത്തോടെ ശേഖരിക്കും. ഇതിൽ നിങ്ങളുടെ മുഴുവൻ പേര്, ജനനത്തീയതി, താമസ വിലാസം, ബന്ധപ്പെടാനുള്ള വിശദാംശങ്ങൾ എന്നിവ ഉൾപ്പെടുന്നു. ബിസിനസ്സ് ഉടമകൾക്കായി, MCA സ്പെസിഫിക്കുകളും GST റിട്ടേൺ ഫയലിംഗുകളുടെ രേഖകളും പോലെ, നിങ്ങളുടെ GSTIN-മായി ബന്ധിപ്പിച്ചിട്ടുള്ള എല്ലാ പ്രസക്തമായ ബിസിനസ്സ് വിശദാംശങ്ങളും ഉൾക്കൊള്ളുന്നതിനായി ഞങ്ങളുടെ ഡാറ്റ ശേഖരണം ഞങ്ങൾ വിപുലീകരിക്കുന്നു.",
    "Our system assesses your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your GSTIN.": "സാമ്പത്തിക ഉൾക്കാഴ്‌ചയ്‌ക്കായി ഞങ്ങളുടെ സിസ്റ്റം നിങ്ങളുടെ CIBIL സ്‌കോർ വിലയിരുത്തുകയും നിങ്ങളുടെ GSTIN-മായി ബന്ധപ്പെട്ട നിലവിലുള്ള ഏതെങ്കിലും കോടതി കേസുകൾ ക്രോസ്-വെരിഫൈ ചെയ്യുകയും ചെയ്യുന്നു.",
    "Error: Invalid GST Format": "പിശക്: അസാധുവായ GST ഫോർമാറ്റ്",
    "Please enter your personal PAN": "നിങ്ങളുടെ സ്വകാര്യ പാൻ നൽകുക",
    "Already Exists": "ഇതിനകം നിലവിലുണ്ട്",
    "Invalid": "അസാധുവാണ്",
    "Secure PAN Validation Process": "സുരക്ഷിത പാൻ മൂല്യനിർണ്ണയ പ്രക്രിയ",
    "This verification process is designed to uphold the integrity of our services, ensuring a reliable and secure experience.": "വിശ്വസനീയവും സുരക്ഷിതവുമായ അനുഭവം ഉറപ്പാക്കിക്കൊണ്ട് ഞങ്ങളുടെ സേവനങ്ങളുടെ സമഗ്രത ഉയർത്തിപ്പിടിക്കുന്നതിനാണ് ഈ സ്ഥിരീകരണ പ്രക്രിയ രൂപകൽപ്പന ചെയ്തിരിക്കുന്നത്.",
    "Enter Your PAN Number": "നിങ്ങളുടെ പാൻ നമ്പർ നൽകുക",
    "Please enter your correct PAN number in the format XXXXX-0000-X.": "XXXXX-0000-X ഫോർമാറ്റിൽ നിങ്ങളുടെ ശരിയായ പാൻ നമ്പർ നൽകുക.",
    "PrivateCourt will meticulously collect essential information associated with your PAN such as your full name, date of birth, residential address, and contact details. For Business Owners, we extend our data collection to encompass all pertinent business details tied to your PAN such as GST details, MCA specifics, and records of GST return filings.": "നിങ്ങളുടെ പൂർണ്ണമായ പേര്, ജനനത്തീയതി, താമസ വിലാസം, ബന്ധപ്പെടാനുള്ള വിശദാംശങ്ങൾ എന്നിവ പോലുള്ള നിങ്ങളുടെ പാൻ നമ്പറുമായി ബന്ധപ്പെട്ട അവശ്യ വിവരങ്ങൾ സ്വകാര്യകോടതി സൂക്ഷ്മമായി ശേഖരിക്കും. ബിസിനസ്സ് ഉടമകൾക്കായി, GST വിശദാംശങ്ങൾ, MCA സവിശേഷതകൾ, GST റിട്ടേൺ ഫയലിംഗുകളുടെ രേഖകൾ എന്നിവ പോലെ നിങ്ങളുടെ പാൻ ബന്ധിപ്പിച്ചിട്ടുള്ള എല്ലാ പ്രസക്തമായ ബിസിനസ്സ് വിശദാംശങ്ങളും ഉൾക്കൊള്ളുന്നതിനായി ഞങ്ങളുടെ ഡാറ്റ ശേഖരണം ഞങ്ങൾ വിപുലീകരിക്കുന്നു.",
    "Our system assesses your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your PAN.": "സാമ്പത്തിക ഉൾക്കാഴ്‌ചയ്‌ക്കായി ഞങ്ങളുടെ സിസ്റ്റം നിങ്ങളുടെ CIBIL സ്‌കോർ വിലയിരുത്തുകയും നിങ്ങളുടെ PAN-മായി ബന്ധപ്പെട്ട നിലവിലുള്ള ഏതെങ്കിലും കോടതി കേസുകൾ ക്രോസ്-വെരിഫൈ ചെയ്യുകയും ചെയ്യുന്നു.",
    "It appears that the PAN details provided belong to a company. We require your personal PAN details for verification purposes.": "നൽകിയിരിക്കുന്ന പാൻ വിശദാംശങ്ങൾ ഒരു കമ്പനിയുടേതാണെന്ന് തോന്നുന്നു. സ്ഥിരീകരണ ആവശ്യങ്ങൾക്കായി ഞങ്ങൾക്ക് നിങ്ങളുടെ സ്വകാര്യ പാൻ വിശദാംശങ്ങൾ ആവശ്യമാണ്.",



    
    "Data Compilation in Progress: Please Allow Processing Time": "ഡാറ്റ സമാഹരണം പുരോഗമിക്കുന്നു: ദയവായി പ്രോസസ്സിംഗ് സമയം അനുവദിക്കുക",
    "Thank you for providing your information. Our system is currently in the process of analyzing and compiling the data you've entered, including details such as PAN, Aadhaar, GST, Email, and more, to establish your comprehensive profile.": "നിങ്ങളുടെ വിവരങ്ങൾ നൽകിയതിന് നന്ദി. നിങ്ങളുടെ സമഗ്രമായ പ്രൊഫൈൽ സ്ഥാപിക്കുന്നതിന് പാൻ, ആധാർ, ജിഎസ്ടി, ഇമെയിൽ എന്നിവയും അതിലേറെയും പോലുള്ള വിശദാംശങ്ങൾ ഉൾപ്പെടെ നിങ്ങൾ നൽകിയ ഡാറ്റ വിശകലനം ചെയ്യുകയും സമാഹരിക്കുകയും ചെയ്യുന്ന പ്രക്രിയയിലാണ് ഞങ്ങളുടെ സിസ്റ്റം.",
    "This operation may take a few moments. During this time, your screen may show minimal activity. Please be assured that this is a routine part of our data compilation process.": "ഈ പ്രവർത്തനത്തിന് കുറച്ച് നിമിഷങ്ങൾ എടുത്തേക്കാം. ഈ സമയത്ത്, നിങ്ങളുടെ സ്‌ക്രീൻ കുറഞ്ഞ പ്രവർത്തനം കാണിച്ചേക്കാം. ഇത് ഞങ്ങളുടെ ഡാറ്റ കംപൈലേഷൻ പ്രക്രിയയുടെ ഒരു പതിവ് ഭാഗമാണെന്ന് ദയവായി ഉറപ്പാക്കുക.",
    "Your profile is being meticulously crafted to uphold accuracy and security. We sincerely appreciate your patience and understanding as we work to enhance your experience.": "കൃത്യതയും സുരക്ഷയും ഉയർത്തിപ്പിടിക്കാൻ നിങ്ങളുടെ പ്രൊഫൈൽ സൂക്ഷ്മമായി രൂപപ്പെടുത്തിയിരിക്കുന്നു. നിങ്ങളുടെ അനുഭവം മെച്ചപ്പെടുത്തുന്നതിനായി ഞങ്ങൾ പ്രവർത്തിക്കുമ്പോൾ നിങ്ങളുടെ ക്ഷമയെയും ധാരണയെയും ഞങ്ങൾ ആത്മാർത്ഥമായി അഭിനന്ദിക്കുന്നു.",
    "For urgent matters or assistance, feel free to call us at +91-9699900111.": "അടിയന്തര കാര്യങ്ങൾക്കോ സഹായത്തിനോ ഞങ്ങളെ +91-9699900111 എന്ന നമ്പറിൽ വിളിക്കുക.",
    "Thank you for your patience and entrusting PrivateCourt. Your security is our priority.": "നിങ്ങളുടെ ക്ഷമയ്ക്കും സ്വകാര്യ കോടതിയെ ഭരമേൽപ്പിച്ചതിനും നന്ദി. നിങ്ങളുടെ സുരക്ഷയാണ് ഞങ്ങളുടെ മുൻഗണന.",
    "Please input your Aadhaar number. This process ensures accurate cross-checking for your security. Thank you for your cooperation.": "ദയവായി നിങ്ങളുടെ ആധാർ നമ്പർ നൽകുക. ഈ പ്രക്രിയ നിങ്ങളുടെ സുരക്ഷയ്ക്കായി കൃത്യമായ ക്രോസ് ചെക്കിംഗ് ഉറപ്പാക്കുന്നു. നിങ്ങളുടെ സഹകരണത്തിന് നന്ദി.",
    "We apologize for the inconvenience. The Aadhaar number entered is invalid. Please double-check and ensure that you have entered the": "അസൗകര്യത്തിൽ ഞങ്ങൾ ക്ഷമ ചോദിക്കുന്നു. നൽകിയ ആധാർ നമ്പർ അസാധുവാണ്. ദയവായി രണ്ടുതവണ പരിശോധിച്ച് നിങ്ങൾ നൽകിയിട്ടുണ്ടെന്ന് ഉറപ്പാക്കുക",
    "Applicant’s correct 12-digit Aadhaar number.": "അപേക്ഷകൻ്റെ ശരിയായ 12 അക്ക ആധാർ നമ്പർ.",
    "Error: Invalid OTP": "പിശക്: OTP അസാധുവാണ്",
    "We apologize for the inconvenience. The OTP entered is incorrect. Please double-check the OTP and try again.": "അസൗകര്യത്തിൽ ഞങ്ങൾ ക്ഷമ ചോദിക്കുന്നു. നൽകിയ OTP തെറ്റാണ്. OTP രണ്ടുതവണ പരിശോധിച്ച് വീണ്ടും ശ്രമിക്കുക.",
    "Welcome": "സ്വാഗതം",
    "Ensuring the accuracy of your bank account information is crucial for a seamless verification process.Kindly enter your valid Account Number, and input the correct IFSC code associated with your account.": "തടസ്സമില്ലാത്ത സ്ഥിരീകരണ പ്രക്രിയയ്ക്ക് നിങ്ങളുടെ ബാങ്ക് അക്കൗണ്ട് വിവരങ്ങളുടെ കൃത്യത ഉറപ്പാക്കുന്നത് നിർണായകമാണ്. ദയവായി നിങ്ങളുടെ സാധുവായ അക്കൗണ്ട് നമ്പർ നൽകുക, നിങ്ങളുടെ അക്കൗണ്ടുമായി ബന്ധപ്പെട്ട ശരിയായ IFSC കോഡ് നൽകുക.",
    "This verification step is designed to enhance the security of our services and provide you with a reliable experience.": "ഞങ്ങളുടെ സേവനങ്ങളുടെ സുരക്ഷ വർധിപ്പിക്കുന്നതിനും നിങ്ങൾക്ക് വിശ്വസനീയമായ അനുഭവം നൽകുന്നതിനുമായാണ് ഈ സ്ഥിരീകരണ ഘട്ടം രൂപകൽപ്പന ചെയ്തിരിക്കുന്നത്.",
    "We apologize for the inconvenience. The name provided does not match the GST records. Please ensure that the name entered matches the information on your GST card and try again.": "അസൗകര്യത്തിൽ ഞങ്ങൾ ക്ഷമ ചോദിക്കുന്നു. നൽകിയിരിക്കുന്ന പേര് GST രേഖകളുമായി പൊരുത്തപ്പെടുന്നില്ല. നൽകിയ പേര് നിങ്ങളുടെ GST കാർഡിലെ വിവരങ്ങളുമായി പൊരുത്തപ്പെടുന്നുണ്ടെന്ന് ഉറപ്പുവരുത്തി വീണ്ടും ശ്രമിക്കുക.",
    "Business Verification Process": "ബിസിനസ്സ് സ്ഥിരീകരണ പ്രക്രിയ",
    "Ensuring Secure Business Validation- This verification process is meticulously designed to uphold the integrity of our services, providing a reliable and secure experience.": "സുരക്ഷിതമായ ബിസിനസ് മൂല്യനിർണ്ണയം ഉറപ്പാക്കുന്നു- ഈ സ്ഥിരീകരണ പ്രക്രിയ, ഞങ്ങളുടെ സേവനങ്ങളുടെ സമഗ്രത ഉയർത്തിപ്പിടിച്ച്, വിശ്വസനീയവും സുരക്ഷിതവുമായ അനുഭവം പ്രദാനം ചെയ്യുന്നതിനാണ്.",
    "Please provide details for each of your businesses for accurate validation.": "കൃത്യമായ മൂല്യനിർണ്ണയത്തിനായി നിങ്ങളുടെ ഓരോ ബിസിനസിൻ്റെയും വിശദാംശങ്ങൾ നൽകുക.",
    "PrivateCourt will collect essential information associated with your PAN such as your full name, date of birth, residential address, and contact details. For Business Owners, we extend our data collection to encompass all pertinent business details tied to your PAN such as GST details, MCA specifics, and records of GST return filings.": "നിങ്ങളുടെ പൂർണ്ണമായ പേര്, ജനനത്തീയതി, താമസ വിലാസം, ബന്ധപ്പെടാനുള്ള വിശദാംശങ്ങൾ എന്നിവ പോലുള്ള നിങ്ങളുടെ പാൻ നമ്പറുമായി ബന്ധപ്പെട്ട അവശ്യ വിവരങ്ങൾ സ്വകാര്യകോടതി ശേഖരിക്കും. ബിസിനസ്സ് ഉടമകൾക്കായി, GST വിശദാംശങ്ങൾ, MCA സവിശേഷതകൾ, GST റിട്ടേൺ ഫയലിംഗുകളുടെ രേഖകൾ എന്നിവ പോലെ നിങ്ങളുടെ പാൻ ബന്ധിപ്പിച്ചിട്ടുള്ള എല്ലാ പ്രസക്തമായ ബിസിനസ്സ് വിശദാംശങ്ങളും ഉൾക്കൊള്ളുന്നതിനായി ഞങ്ങളുടെ ഡാറ്റ ശേഖരണം ഞങ്ങൾ വിപുലീകരിക്കുന്നു.",
    "Our system evaluates your CIBIL score for financial insight and cross-verifies for any ongoing court cases associated with your PAN.": "സാമ്പത്തിക ഉൾക്കാഴ്‌ചയ്‌ക്കായി ഞങ്ങളുടെ സിസ്റ്റം നിങ്ങളുടെ CIBIL സ്‌കോർ വിലയിരുത്തുകയും നിങ്ങളുടെ PAN-മായി ബന്ധപ്പെട്ട നിലവിലുള്ള ഏതെങ്കിലും കോടതി കേസുകൾ ക്രോസ്-വെരിഫൈ ചെയ്യുകയും ചെയ്യുന്നു.",
    "To ensure the security of your account, please enter your valid Email ID in the specified format- username@domain.com. If your email address doesn't exist in our system, a": "നിങ്ങളുടെ അക്കൗണ്ടിൻ്റെ സുരക്ഷ ഉറപ്പാക്കാൻ, നിർദ്ദിഷ്ട ഫോർമാറ്റിൽ നിങ്ങളുടെ സാധുവായ ഇമെയിൽ ഐഡി നൽകുക- username@domain.com. നിങ്ങളുടെ ഇമെയിൽ വിലാസം ഞങ്ങളുടെ സിസ്റ്റത്തിൽ നിലവിലില്ലെങ്കിൽ, എ",
    "account will be created for you.": "നിങ്ങൾക്കായി അക്കൗണ്ട് സൃഷ്ടിക്കും.",
    "GST Verification Process": "ജിഎസ്ടി സ്ഥിരീകരണ പ്രക്രിയ",
    "We regret to inform you that the GST number entered does not match the required format (00-XXXXX-0000-X-0-X-0).Please ensure you have entered the correct GST details and follow the specified format for accurate verification.": "നൽകിയ GST നമ്പർ ആവശ്യമായ ഫോർമാറ്റുമായി (00-XXXXX-0000-X-0-X-0) പൊരുത്തപ്പെടുന്നില്ലെന്ന് നിങ്ങളെ അറിയിക്കുന്നതിൽ ഞങ്ങൾ ഖേദിക്കുന്നു. നിങ്ങൾ ശരിയായ GST വിശദാംശങ്ങൾ നൽകിയിട്ടുണ്ടെന്ന് ഉറപ്പുവരുത്തുകയും കൃത്യമായ സ്ഥിരീകരണത്തിനായി നിർദ്ദിഷ്ട ഫോർമാറ്റ് പിന്തുടരുകയും ചെയ്യുക.",
    "Verify GST": "GST പരിശോധിക്കുക",
    "Congratulations! You've completed the registration process. Your dedication to providing the necessary information has brought us one step closer to serving you effectively.": "അഭിനന്ദനങ്ങൾ! നിങ്ങൾ രജിസ്ട്രേഷൻ പ്രക്രിയ പൂർത്തിയാക്കി. ആവശ്യമായ വിവരങ്ങൾ നൽകാനുള്ള നിങ്ങളുടെ സമർപ്പണം നിങ്ങളെ ഫലപ്രദമായി സേവിക്കുന്നതിന് ഞങ്ങളെ ഒരു പടി കൂടി അടുപ്പിച്ചു.",
    "We apologize for the inconvenience. The name provided does not match the GST records. Please ensure that the name entered matches the information on your GST card and try again.": "അസൗകര്യത്തിൽ ഞങ്ങൾ ക്ഷമ ചോദിക്കുന്നു. നൽകിയിരിക്കുന്ന പേര് GST രേഖകളുമായി പൊരുത്തപ്പെടുന്നില്ല. നൽകിയ പേര് നിങ്ങളുടെ GST കാർഡിലെ വിവരങ്ങളുമായി പൊരുത്തപ്പെടുന്നുണ്ടെന്ന് ഉറപ്പുവരുത്തി വീണ്ടും ശ്രമിക്കുക.",
    "Skip": "ഒഴിവാക്കുക",
    "Server Overload: Please Retry Later": "സെർവർ ഓവർലോഡ്: ദയവായി പിന്നീട് വീണ്ടും ശ്രമിക്കുക",
    "Back To Home": "തിരികെ വീട്ടിലേക്ക്",

   // webpage TEXT
   "Payment Date":"പേയ്മെന്റ് തീയതി",
   "Aadhaar Verification":"ആധാർ പരിശോധന",
    "To proceed further, we have initiated the Aadhaar verification process. Please provide the OTP (One-Time Password) that you have received to complete this step. Ensuring the security and authenticity of our users is paramount to us, and this verification step helps us maintain the integrity of our services.":"കൂടുതൽ മുന്നോട്ട് പോകുന്നതിന്, ഞങ്ങൾ ആധാർ വെരിഫിക്കേഷൻ പ്രക്രിയ ആരംഭിച്ചു. ഈ ഘട്ടം പൂർത്തിയാക്കുന്നതിന് നിങ്ങൾക്ക് ലഭിച്ച OTP (ഒറ്റത്തവണ പാസ് വേഡ്) നൽകുക. ഞങ്ങളുടെ ഉപയോക്താക്കളുടെ സുരക്ഷയും ആധികാരികതയും ഉറപ്പാക്കുന്നത് ഞങ്ങൾക്ക് പരമപ്രധാനമാണ്, ഈ പരിശോധനാ ഘട്ടം ഞങ്ങളുടെ സേവനങ്ങളുടെ സമഗ്രത നിലനിർത്താൻ ഞങ്ങളെ സഹായിക്കുന്നു.",
    "Thank You Aadhaar Verification Successfully":"ആധാർ പരിശോധന വിജയകരമായി പൂർത്തിയാക്കിയതിന് നന്ദി",
    "Thank you for providing the OTP for Aadhaar verification. With this step completed, we are now ready to proceed with your case.":"ആധാർ വെരിഫിക്കേഷനായി ഒടിപി നൽകിയതിന് നന്ദി. ഈ ഘട്ടം പൂർത്തിയായതോടെ, നിങ്ങളുടെ കേസുമായി മുന്നോട്ട് പോകാൻ ഞങ്ങൾ ഇപ്പോൾ തയ്യാറാണ്.",
    "Our team will continue to work diligently to bring about a resolution that meets your needs and expectations. Should you have any questions or concerns along the way, please don't hesitate to reach out to us.":"നിങ്ങളുടെ ആവശ്യങ്ങളും പ്രതീക്ഷകളും നിറവേറ്റുന്ന ഒരു തീരുമാനം കൊണ്ടുവരുന്നതിന് ഞങ്ങളുടെ ടീം ഉത്സാഹത്തോടെ പ്രവർത്തിക്കുന്നത് തുടരും. വഴിയിൽ നിങ്ങൾക്ക് എന്തെങ്കിലും ചോദ്യങ്ങളോ ആശങ്കകളോ ഉണ്ടെങ്കിൽ, ദയവായി ഞങ്ങളെ സമീപിക്കാൻ മടിക്കരുത്.",
    "Once again, thank you for entrusting PrivateCourt with your dispute resolution needs. We look forward to serving you.":"നിങ്ങളുടെ തർക്ക പരിഹാര ആവശ്യങ്ങൾ പ്രൈവറ്റ് കോടതിയെ ഏൽപ്പിച്ചതിന് ഒരിക്കൽ കൂടി നന്ദി. നിങ്ങളെ സേവിക്കാൻ ഞങ്ങൾ ആഗ്രഹിക്കുന്നു.",
    // "Warm regards,":"",
    // "PrivateCourt Team":"",
    'Oops!':"അയ്യോ!",
    "The page you are searching, can't be found.":"നിങ്ങൾ തിരയുന്ന പേജ്, കണ്ടെത്താൻ കഴിയില്ല.",
    "Language Selection:": "ಭಾಷೆಯ ಆಯ್ಕೆ:",
    "Empowering Effective Communication": "ಪರಿಣಾಮಕಾರಿ ಸಂವಹನವನ್ನು ಸಶಕ್ತಗೊಳಿಸುವುದು",
    "Good day! I'm Murthy, your dedicated assistant for today's proceedings. Lets start by selecting the language you're most comfortable with.":"ಶುಭ ದಿನ! ನಾನು ಮೂರ್ತಿ, ಇಂದಿನ ಕಾರ್ಯವೈಖರಿಗೆ ನಿಮ್ಮ ಸಮರ್ಪಿತ ಸಹಾಯಕ. ನೀವು ಹೆಚ್ಚು ಆರಾಮದಾಯಕವಾಗಿರುವ ಭಾಷೆಯನ್ನು ಆಯ್ಕೆ ಮಾಡುವ ಮೂಲಕ ಪ್ರಾರಂಭಿಸೋಣ.",
    "Select Your Preferred Language": "ನಿಮ್ಮ ಆದ್ಯತೆಯ ಭಾಷೆಯನ್ನು ಆಯ್ಕೆಮಾಡಿ",
    "Opting for your preferred language ensures clear and effective communication. Whether it's your native tongue or another language of your choice, selecting it will streamline our interaction and enhance your experience.":"ನಿಮ್ಮ ಆದ್ಯತೆಯ ಭಾಷೆಯ ಆಯ್ಕೆಯು ಸ್ಪಷ್ಟ ಮತ್ತು ಪರಿಣಾಮಕಾರಿ ಸಂವಹನವನ್ನು ಖಚಿತಪಡಿಸುತ್ತದೆ. ಅದು ನಿಮ್ಮ ಮಾತೃಭಾಷೆಯಾಗಿರಲಿ ಅಥವಾ ನಿಮ್ಮ ಆಯ್ಕೆಯ ಇನ್ನೊಂದು ಭಾಷೆಯಾಗಿರಲಿ, ಅದನ್ನು ಆಯ್ಕೆ ಮಾಡುವುದರಿಂದ ನಮ್ಮ ಸಂವಹನವನ್ನು ಸುಗಮಗೊಳಿಸುತ್ತದೆ ಮತ್ತು ನಿಮ್ಮ ಅನುಭವವನ್ನು ಹೆಚ್ಚಿಸುತ್ತದೆ.",
    "Next": "ಮುಂದೆ",
    "Welcome to PrivateCourt, where Helping you is our priority.": "ಖಾಸಗಿ ನ್ಯಾಯಾಲಯಕ್ಕೆ ಸುಸ್ವಾಗತ, ಅಲ್ಲಿ ನಿಮಗೆ ಸಹಾಯ ಮಾಡುವುದು ನಮ್ಮ ಆದ್ಯತೆಯಾಗಿದೆ.",
    "Now, let me introduce you to PrivateCourt – your reliable partner in resolving lending disputes effectively and efficiently.":"ಈಗ, ನಾನು ನಿಮ್ಮನ್ನು ಖಾಸಗಿ ನ್ಯಾಯಾಲಯಕ್ಕೆ ಪರಿಚಯಿಸುತ್ತೇನೆ - ಸಾಲ ವಿವಾದಗಳನ್ನು ಪರಿಣಾಮಕಾರಿಯಾಗಿ ಮತ್ತು ಪರಿಣಾಮಕಾರಿಯಾಗಿ ಪರಿಹರಿಸುವಲ್ಲಿ ನಿಮ್ಮ ವಿಶ್ವಾಸಾರ್ಹ ಪಾಲುದಾರ.",
    "PrivateCourt":"ಖಾಸಗಿ ನ್ಯಾಯಾಲಯ", 
    "Your trusted partner in resolving lending disputes with care and expertise.":" ಕಾಳಜಿ ಮತ್ತು ಪರಿಣತಿಯೊಂದಿಗೆ ಸಾಲ ವಿವಾದಗಳನ್ನು ಪರಿಹರಿಸುವಲ್ಲಿ ನಿಮ್ಮ ವಿಶ್ವಾಸಾರ್ಹ ಪಾಲುದಾರ.",
    "Welcome to PrivateCourt, where your case details are in good hands. With the lending company initiating arbitration or conciliation, it's essential for us to understand your situation fully. Please share all the details you can.":"ಖಾಸಗಿ ನ್ಯಾಯಾಲಯಕ್ಕೆ ಸುಸ್ವಾಗತ, ಅಲ್ಲಿ ನಿಮ್ಮ ಪ್ರಕರಣದ ವಿವರಗಳು ಉತ್ತಮ ಕೈಯಲ್ಲಿವೆ. ಸಾಲ ನೀಡುವ ಕಂಪನಿಯು ಮಧ್ಯಸ್ಥಿಕೆ ಅಥವಾ ಸಂಧಾನವನ್ನು ಪ್ರಾರಂಭಿಸುವುದರೊಂದಿಗೆ, ನಿಮ್ಮ ಪರಿಸ್ಥಿತಿಯನ್ನು ಸಂಪೂರ್ಣವಾಗಿ ಅರ್ಥಮಾಡಿಕೊಳ್ಳುವುದು ನಮಗೆ ಅತ್ಯಗತ್ಯ. ದಯವಿಟ್ಟು ನೀವು ಮಾಡಬಹುದಾದ ಎಲ್ಲಾ ವಿವರಗಳನ್ನು ಹಂಚಿಕೊಳ್ಳಿ.",
    "The more information we have, the better we can help. Your input is crucial in finding the right solution for you.":"ನಾವು ಹೆಚ್ಚು ಮಾಹಿತಿಯನ್ನು ಹೊಂದಿದ್ದೇವೆ, ನಾವು ಉತ್ತಮವಾಗಿ ಸಹಾಯ ಮಾಡಬಹುದು. ನಿಮಗಾಗಿ ಸರಿಯಾದ ಪರಿಹಾರವನ್ನು ಹುಡುಕುವಲ್ಲಿ ನಿಮ್ಮ ಇನ್ಪುಟ್ ನಿರ್ಣಾಯಕವಾಗಿದೆ.",
    "Trust us to carefully examine your case and work together to find the best way forward.":"ನಿಮ್ಮ ಪ್ರಕರಣವನ್ನು ಎಚ್ಚರಿಕೆಯಿಂದ ಪರೀಕ್ಷಿಸಲು ಮತ್ತು ಮುಂದೆ ಉತ್ತಮ ಮಾರ್ಗವನ್ನು ಕಂಡುಹಿಡಿಯಲು ಒಟ್ಟಾಗಿ ಕೆಲಸ ಮಾಡಲು ನಮ್ಮನ್ನು ನಂಬಿರಿ.",
    "Reviewing Your Dispute Information": "ನಿಮ್ಮ ವಿವಾದದ ಮಾಹಿತಿಯನ್ನು ಪರಿಶೀಲಿಸಲಾಗುತ್ತಿದೆ",
    "Let's take a closer look at the details surrounding your dispute. The information we're discussing today has been shared with us by Company, regarding a personal loan.": "ನಿಮ್ಮ ವಿವಾದದ ಸುತ್ತಲಿನ ವಿವರಗಳನ್ನು ಹತ್ತಿರದಿಂದ ನೋಡೋಣ. ನಾವು ಇಂದು ಚರ್ಚಿಸುತ್ತಿರುವ ಮಾಹಿತಿಯನ್ನು Company ಮೂಲಕ ವೈಯಕ್ತಿಕ ಸಾಲಕ್ಕೆ ಸಂಬಂಧಿಸಿದಂತೆ ನಮ್ಮೊಂದಿಗೆ ಹಂಚಿಕೊಳ್ಳಲಾಗಿದೆ..",
    "By understanding these specifics, we can better assess the situation and formulate a resolution plan that aligns with your circumstances and goals.":"ಈ ನಿಶ್ಚಿತಗಳನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳುವ ಮೂಲಕ, ನಾವು ಪರಿಸ್ಥಿತಿಯನ್ನು ಉತ್ತಮವಾಗಿ ನಿರ್ಣಯಿಸಬಹುದು ಮತ್ತು ನಿಮ್ಮ ಸಂದರ್ಭಗಳು ಮತ್ತು ಗುರಿಗಳಿಗೆ ಹೊಂದಿಕೆಯಾಗುವ ರೆಸಲ್ಯೂಶನ್ ಯೋಜನೆಯನ್ನು ರೂಪಿಸಬಹುದು.",
    "Dispute Amount Information": "ವಿವಾದದ ಮೊತ್ತದ ಮಾಹಿತಿ",
    "According to their records, you initially borrowed an amount of": "ಅವರ ದಾಖಲೆಗಳ ಪ್ರಕಾರ, ನೀವು ಆರಂಭದಲ್ಲಿ ಮೊತ್ತವನ್ನು ಎರವಲು ಪಡೆದಿದ್ದೀರಿ",
    "Presently, your outstanding due stands at Rs.": "ಪ್ರಸ್ತುತ, ನಿಮ್ಮ ಬಾಕಿಯು ರೂ.",
    "These details provide us with valuable insight into the history and current status of your loan.":"ಈ ವಿವರಗಳು ನಿಮ್ಮ ಸಾಲದ ಇತಿಹಾಸ ಮತ್ತು ಪ್ರಸ್ತುತ ಸ್ಥಿತಿಯ ಬಗ್ಗೆ ನಮಗೆ ಮೌಲ್ಯಯುತವಾದ ಒಳನೋಟವನ್ನು ಒದಗಿಸುತ್ತದೆ.",
    "Spotting Financial Issues: Understanding Unpaid Obligations": "ಹಣಕಾಸಿನ ಸಮಸ್ಯೆಗಳನ್ನು ಗುರುತಿಸುವುದು: ಪಾವತಿಸದ ಕಟ್ಟುಪಾಡುಗಳನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳುವುದು",
    "Now, let's gently explore the challenges you've faced due to financial constraints. Discussing this may be sensitive, but your openness will greatly assist us in finding the best path forward. Please take a moment to select any of the following options that resonate with your experience:":"ಈಗ, ಹಣಕಾಸಿನ ನಿರ್ಬಂಧಗಳಿಂದಾಗಿ ನೀವು ಎದುರಿಸಿದ ಸವಾಲುಗಳನ್ನು ನಿಧಾನವಾಗಿ ಅನ್ವೇಷಿಸೋಣ. ಇದನ್ನು ಚರ್ಚಿಸುವುದು ಸೂಕ್ಷ್ಮವಾಗಿರಬಹುದು, ಆದರೆ ನಿಮ್ಮ ಮುಕ್ತತೆ ಮುಂದೆ ಉತ್ತಮ ಮಾರ್ಗವನ್ನು ಕಂಡುಕೊಳ್ಳುವಲ್ಲಿ ನಮಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ. ನಿಮ್ಮ ಅನುಭವದೊಂದಿಗೆ ಅನುರಣಿಸುವ ಕೆಳಗಿನ ಯಾವುದೇ ಆಯ್ಕೆಗಳನ್ನು ಆಯ್ಕೆ ಮಾಡಲು ಸ್ವಲ್ಪ ಸಮಯ ತೆಗೆದುಕೊಳ್ಳಿ:",
    "Financial Loss": "ಆರ್ಥಿಕ ನಷ್ಟ",
    "Business Loss": "ವ್ಯಾಪಾರ ನಷ್ಟ",
    "Job Loss": "ಉದ್ಯೋಗ ನಷ್ಟ",
    "Health Issue": "ಆರೋಗ್ಯ ಸಮಸ್ಯೆ",
    "Death of the earning member": "ಗಳಿಸುವ ಸದಸ್ಯರ ಸಾವು",
    "Missing payment details": "ಪಾವತಿ ವಿವರಗಳು ಕಾಣೆಯಾಗಿದೆ",
    "Dispute due to bad behaviour": "ಕೆಟ್ಟ ನಡವಳಿಕೆಯಿಂದಾಗಿ ವಿವಾದ",
    "No clear loan information": "ಯಾವುದೇ ಸ್ಪಷ್ಟ ಸಾಲದ ಮಾಹಿತಿ ಇಲ್ಲ",
    "Death Case": "ಸಾವಿನ ಪ್ರಕರಣ",
    "We kindly request you to upload the death certificate at":"Legal@privatecourt.in ನಲ್ಲಿ ಮರಣ ಪ್ರಮಾಣಪತ್ರವನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡಲು ನಾವು ದಯೆಯಿಂದ ವಿನಂತಿಸುತ್ತೇವೆ",
    "Other reason/s": "ಇತರೆ ಕಾರಣ/ಗಳು",
    "Your prompt response will greatly assist us in assessing the situation and finding the most suitable resolution. Rest assured, we are committed to working with you to resolve any outstanding issues and ensure a fair and satisfactory outcome.":"ನಿಮ್ಮ ಪ್ರಾಂಪ್ಟ್ ಪ್ರತಿಕ್ರಿಯೆಯು ಪರಿಸ್ಥಿತಿಯನ್ನು ನಿರ್ಣಯಿಸಲು ಮತ್ತು ಹೆಚ್ಚು ಸೂಕ್ತವಾದ ರೆಸಲ್ಯೂಶನ್ ಅನ್ನು ಕಂಡುಹಿಡಿಯಲು ನಮಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ. ಖಚಿತವಾಗಿರಿ, ಯಾವುದೇ ಬಾಕಿ ಉಳಿದಿರುವ ಸಮಸ್ಯೆಗಳನ್ನು ಪರಿಹರಿಸಲು ಮತ್ತು ನ್ಯಾಯಯುತ ಮತ್ತು ತೃಪ್ತಿದಾಯಕ ಫಲಿತಾಂಶವನ್ನು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಲು ನಿಮ್ಮೊಂದಿಗೆ ಕೆಲಸ ಮಾಡಲು ನಾವು ಬದ್ಧರಾಗಿದ್ದೇವೆ.",
    "Partner Dispute": "ಪಾಲುದಾರ ವಿವಾದ",
    "Funds held with other vendors or clients": "ಇತರ ಮಾರಾಟಗಾರರು ಅಥವಾ ಕ್ಲೈಂಟ್‌ಗಳೊಂದಿಗೆ ಹೊಂದಿರುವ ನಿಧಿಗಳು",
    "Monetary loss through other means": "ಇತರ ವಿಧಾನಗಳಿಂದ ವಿತ್ತೀಯ ನಷ್ಟ",
    "Less than 2 months": "ಎರಡು ತಿಂಗಳಿಗಿಂತ ಕಡಿಮೆ",
    "Less than 6 months": "ಆರು ತಿಂಗಳಿಗಿಂತ ಕಡಿಮೆ",
    "Less than 1 year": "ಒಂದು ವರ್ಷಕ್ಕಿಂತ ಕಡಿಮೆ",
    "More than 1 year": "ಒಂದು ವರ್ಷಕ್ಕಿಂತ ಹೆಚ್ಚು",
    "Exploring Settlement Possibilities": "ವಸಾಹತು ಸಾಧ್ಯತೆಗಳನ್ನು ಅನ್ವೇಷಿಸುವುದು",
    "As our discussion progresses, I appreciate your openness in explaining the reason for the default. It's crucial to address your outstanding dues promptly to prevent any legal complexities. We recommend exploring different avenues, such as seeking assistance from family or investigating alternative financing options, to fulfill your obligations without delay.":"ನಮ್ಮ ಚರ್ಚೆ ಮುಂದುವರೆದಂತೆ, ಡೀಫಾಲ್ಟ್‌ಗೆ ಕಾರಣವನ್ನು ವಿವರಿಸುವಲ್ಲಿ ನಿಮ್ಮ ಮುಕ್ತತೆಯನ್ನು ನಾನು ಪ್ರಶಂಸಿಸುತ್ತೇನೆ. ಯಾವುದೇ ಕಾನೂನು ಸಂಕೀರ್ಣತೆಗಳನ್ನು ತಡೆಗಟ್ಟಲು ನಿಮ್ಮ ಬಾಕಿಯನ್ನು ತ್ವರಿತವಾಗಿ ಪರಿಹರಿಸಲು ಇದು ನಿರ್ಣಾಯಕವಾಗಿದೆ. ನಿಮ್ಮ ಜವಾಬ್ದಾರಿಗಳನ್ನು ವಿಳಂಬವಿಲ್ಲದೆ ಪೂರೈಸಲು ಕುಟುಂಬದಿಂದ ಸಹಾಯ ಪಡೆಯಲು ಅಥವಾ ಪರ್ಯಾಯ ಹಣಕಾಸು ಆಯ್ಕೆಗಳನ್ನು ತನಿಖೆ ಮಾಡುವಂತಹ ವಿವಿಧ ಮಾರ್ಗಗಳನ್ನು ಅನ್ವೇಷಿಸಲು ನಾವು ಶಿಫಾರಸು ಮಾಡುತ್ತೇವೆ.",
    "Timely settlement not only mitigates legal risks but also strengthens trust and credibility in your financial interactions. Your cooperation in this matter is truly valued.":"ಸಮಯೋಚಿತ ಪರಿಹಾರವು ಕಾನೂನು ಅಪಾಯಗಳನ್ನು ತಗ್ಗಿಸುವುದು ಮಾತ್ರವಲ್ಲದೆ ನಿಮ್ಮ ಹಣಕಾಸಿನ ಸಂವಹನಗಳಲ್ಲಿ ನಂಬಿಕೆ ಮತ್ತು ವಿಶ್ವಾಸಾರ್ಹತೆಯನ್ನು ಬಲಪಡಿಸುತ್ತದೆ. ಈ ವಿಷಯದಲ್ಲಿ ನಿಮ್ಮ ಸಹಕಾರವು ನಿಜವಾಗಿಯೂ ಮೌಲ್ಯಯುತವಾಗಿದೆ.",
    "Settlement Options": "ವಸಾಹತು ಆಯ್ಕೆಗಳು",
    "In our efforts to facilitate a swift resolution, we seek your cooperation.":"ತ್ವರಿತ ಪರಿಹಾರವನ್ನು ಸುಲಭಗೊಳಿಸುವ ನಮ್ಮ ಪ್ರಯತ್ನಗಳಲ್ಲಿ, ನಾವು ನಿಮ್ಮ ಸಹಕಾರವನ್ನು ಬಯಸುತ್ತೇವೆ.",
    "Are you open to discussing the settlement of your outstanding dues?": "ನಿಮ್ಮ ಬಾಕಿಯ ಬಾಕಿಗಳ ಪರಿಹಾರವನ್ನು ಚರ್ಚಿಸಲು ನೀವು ಮುಕ್ತರಾಗಿದ್ದೀರಾ?",
    "Please Select": "ದಯವಿಟ್ಟು ಆಯ್ಕೆ ಮಾಡು",
    "Yes": "ಹೌದು",
    "No": "ಸಂ",
    "We appreciate your openness to resolving your outstanding dues. To better understand your financial situation and tailor our approach accordingly,":"",
    "could you kindly provide details on your current family income?":"",
    "In our efforts to address your outstanding dues, we'd like to know if a ":"",
    "one-time payment settlement is an option for you.":"",
    "I am unable to pay by any means": "ನಾನು ಯಾವುದೇ ರೀತಿಯಲ್ಲಿ ಪಾವತಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ",
    "We Appreciate Your Honesty.": "ನಿಮ್ಮ ಪ್ರಾಮಾಣಿಕತೆಯನ್ನು ನಾವು ಪ್ರಶಂಸಿಸುತ್ತೇವೆ.",
    "Aadhaar Verify": "ಆಧಾರ್ ಪರಿಶೀಲನೆ",
    "We appreciate your openness to resolving your outstanding dues. To better understand your financial situation and tailor our approach accordingly, could you kindly provide details on your current family income?":"ನಿಮ್ಮ ಬಾಕಿಯನ್ನು ಪರಿಹರಿಸಲು ನಿಮ್ಮ ಮುಕ್ತತೆಯನ್ನು ನಾವು ಪ್ರಶಂಸಿಸುತ್ತೇವೆ. ನಿಮ್ಮ ಹಣಕಾಸಿನ ಪರಿಸ್ಥಿತಿಯನ್ನು ಚೆನ್ನಾಗಿ ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ಮತ್ತು ಅದಕ್ಕೆ ಅನುಗುಣವಾಗಿ ನಮ್ಮ ವಿಧಾನವನ್ನು ಹೊಂದಿಸಲು, ನಿಮ್ಮ ಪ್ರಸ್ತುತ ಕುಟುಂಬದ ಆದಾಯದ ವಿವರಗಳನ್ನು ನೀವು ದಯೆಯಿಂದ ನೀಡಬಹುದೇ?",
    "No Income": "ಆದಾಯವಿಲ್ಲ",
    "less than Rs. 10000/- per month": "ರೂ.ಗಿಂತ ಕಡಿಮೆ 10000/- ತಿಂಗಳಿಗೆ",
    "less than Rs. 20000/- per month": "ರೂ.ಗಿಂತ ಕಡಿಮೆ 20000/- ತಿಂಗಳಿಗೆ",
    "less than Rs. 40000/- per month": "ರೂ.ಗಿಂತ ಕಡಿಮೆ 40000/- ತಿಂಗಳಿಗೆ",
    "more than Rs. 100000/- per month": "ಹೆಚ್ಚು ರೂ. 100000/- ತಿಂಗಳಿಗೆ",
    "In our efforts to address your outstanding dues, we'd like to know if a one-time payment settlement is an option for you.":"ನಿಮ್ಮ ಬಾಕಿ ಇರುವ ಬಾಕಿಗಳನ್ನು ಪರಿಹರಿಸುವ ನಮ್ಮ ಪ್ರಯತ್ನಗಳಲ್ಲಿ, ಒಂದು-ಬಾರಿ ಪಾವತಿಯ ಸೆಟಲ್‌ಮೆಂಟ್ ನಿಮಗೆ ಆಯ್ಕೆಯಾಗಿದೆಯೇ ಎಂದು ತಿಳಿಯಲು ನಾವು ಬಯಸುತ್ತೇವೆ.",
    "We appreciate your feedback. If a one-time payment is not viable, could you consider arranging payment in three equal instalments?":"ನಿಮ್ಮ ಪ್ರತಿಕ್ರಿಯೆಯನ್ನು ನಾವು ಪ್ರಶಂಸಿಸುತ್ತೇವೆ. ಒಂದು ಬಾರಿ ಪಾವತಿಯು ಕಾರ್ಯಸಾಧ್ಯವಾಗಿಲ್ಲದಿದ್ದರೆ, ನೀವು ಮೂರು ಸಮಾನ ಕಂತುಗಳಲ್ಲಿ ಪಾವತಿಯನ್ನು ಏರ್ಪಡಿಸುವುದನ್ನು ಪರಿಗಣಿಸಬಹುದೇ?",
    "This approach could help ease any immediate financial strain while still addressing the outstanding dues.":"ಬಾಕಿ ಇರುವ ಬಾಕಿಗಳನ್ನು ಪರಿಹರಿಸುವಾಗ ಈ ವಿಧಾನವು ಯಾವುದೇ ತಕ್ಷಣದ ಆರ್ಥಿಕ ಒತ್ತಡವನ್ನು ಕಡಿಮೆ ಮಾಡಲು ಸಹಾಯ ಮಾಡುತ್ತದೆ.",
    "Aadhaar Verification": "ಆಧಾರ್ ಪರಿಶೀಲನೆ",
    "To proceed further, we have initiated the Aadhaar verification process. Please provide the OTP (One-Time Password) that you have received to complete this step. Ensuring the security and authenticity of our users is paramount to us, and this verification step helps us maintain the integrity of our services.":"ಮುಂದುವರಿಯಲು, ನಾವು ಆಧಾರ್ ಪರಿಶೀಲನೆ ಪ್ರಕ್ರಿಯೆಯನ್ನು ಪ್ರಾರಂಭಿಸಿದ್ದೇವೆ. ಈ ಹಂತವನ್ನು ಪೂರ್ಣಗೊಳಿಸಲು ನೀವು ಸ್ವೀಕರಿಸಿದ OTP (ಒಂದು-ಬಾರಿ ಪಾಸ್‌ವರ್ಡ್) ಅನ್ನು ಒದಗಿಸಿ. ನಮ್ಮ ಬಳಕೆದಾರರ ಸುರಕ್ಷತೆ ಮತ್ತು ದೃಢೀಕರಣವನ್ನು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳುವುದು ನಮಗೆ ಅತ್ಯುನ್ನತವಾಗಿದೆ ಮತ್ತು ಈ ಪರಿಶೀಲನಾ ಹಂತವು ನಮ್ಮ ಸೇವೆಗಳ ಸಮಗ್ರತೆಯನ್ನು ಕಾಪಾಡಿಕೊಳ್ಳಲು ನಮಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ.",
    "Enter the Applicant’s Aadhaar Number": "ಅರ್ಜಿದಾರರ ಆಧಾರ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ",
    "Please input the Applicant’s Aadhaar number along with essential demographic details—name, date of birth, and gender. This process ensures accurate cross-checking for security. Thank you for your cooperation.":"ದಯವಿಟ್ಟು ಅಗತ್ಯ ಜನಸಂಖ್ಯಾ ವಿವರಗಳೊಂದಿಗೆ ಅರ್ಜಿದಾರರ ಆಧಾರ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ-ಹೆಸರು, ಹುಟ್ಟಿದ ದಿನಾಂಕ ಮತ್ತು ಲಿಂಗ. ಈ ಪ್ರಕ್ರಿಯೆಯು ಸುರಕ್ಷತೆಗಾಗಿ ನಿಖರವಾದ ಅಡ್ಡ-ಪರಿಶೀಲನೆಯನ್ನು ಖಚಿತಪಡಿಸುತ್ತದೆ. ನಿಮ್ಮ ಸಹಕಾರಕ್ಕಾಗಿ ಧನ್ಯವಾದಗಳು.",
    "For any queries or assistance, feel free to call us at +91-9699900111.": "ಯಾವುದೇ ಪ್ರಶ್ನೆಗಳು ಅಥವಾ ಸಹಾಯಕ್ಕಾಗಿ, +91-9699900111 ನಲ್ಲಿ ನಮಗೆ ಕರೆ ಮಾಡಲು ಮುಕ್ತವಾಗಿರಿ.",
    "Thank you for entrusting PrivateCourt. Your security is our priority.": "ಖಾಸಗಿ ನ್ಯಾಯಾಲಯವನ್ನು ವಹಿಸಿದ್ದಕ್ಕಾಗಿ ಧನ್ಯವಾದಗಳು. ನಿಮ್ಮ ಭದ್ರತೆ ನಮ್ಮ ಆದ್ಯತೆಯಾಗಿದೆ.",
    "Enter the Aadhaar OTP Here": "ಇಲ್ಲಿ ಆಧಾರ್ OTP ಅನ್ನು ನಮೂದಿಸಿ",
    "Verify OTP": "OTP ಪರಿಶೀಲಿಸಿ",
    "Thank You Aadhaar Verification Successfully": "ಧನ್ಯವಾದಗಳು ಆಧಾರ್ ಪರಿಶೀಲನೆ ಯಶಸ್ವಿಯಾಗಿದೆ",
    "Thank you for providing the OTP for Aadhaar verification. With this step completed, we are now ready to proceed with your case.":"ಆಧಾರ್ ಪರಿಶೀಲನೆಗಾಗಿ OTP ಒದಗಿಸಿದ್ದಕ್ಕಾಗಿ ಧನ್ಯವಾದಗಳು. ಈ ಹಂತವು ಪೂರ್ಣಗೊಂಡ ನಂತರ, ನಿಮ್ಮ ಪ್ರಕರಣವನ್ನು ಮುಂದುವರಿಸಲು ನಾವು ಈಗ ಸಿದ್ಧರಾಗಿದ್ದೇವೆ.",
    "Our team will continue to work diligently to bring about a resolution that meets your needs and expectations. Should you have any questions or concerns along the way, please don't hesitate to reach out to us.":"ನಿಮ್ಮ ಅಗತ್ಯತೆಗಳು ಮತ್ತು ನಿರೀಕ್ಷೆಗಳನ್ನು ಪೂರೈಸುವ ನಿರ್ಣಯವನ್ನು ತರಲು ನಮ್ಮ ತಂಡವು ಶ್ರದ್ಧೆಯಿಂದ ಕೆಲಸ ಮಾಡುವುದನ್ನು ಮುಂದುವರಿಸುತ್ತದೆ. ದಾರಿಯುದ್ದಕ್ಕೂ ನೀವು ಯಾವುದೇ ಪ್ರಶ್ನೆಗಳನ್ನು ಅಥವಾ ಕಾಳಜಿಗಳನ್ನು ಹೊಂದಿದ್ದರೆ, ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಲು ಹಿಂಜರಿಯಬೇಡಿ.",
    "Once again, thank you for entrusting PrivateCourt with your dispute resolution needs. We look forward to serving you.":"ಮತ್ತೊಮ್ಮೆ, ನಿಮ್ಮ ವಿವಾದ ಪರಿಹಾರದ ಅಗತ್ಯತೆಗಳನ್ನು ಖಾಸಗಿ ನ್ಯಾಯಾಲಯಕ್ಕೆ ವಹಿಸಿದ್ದಕ್ಕಾಗಿ ಧನ್ಯವಾದಗಳು. ನಿಮ್ಮ ಸೇವೆಗಾಗಿ ನಾವು ಎದುರು ನೋಡುತ್ತಿದ್ದೇವೆ.",
    "Warm regards,": "ಶುಭಾಶಯಗಳೊಂದಿಗೆ,",
    "PrivateCourt Team": "ಖಾಸಗಿ ನ್ಯಾಯಾಲಯದ ತಂಡ", 'Oops!': "അയ്യോ!",
    "The page you are searching, can't be found.": "നിങ്ങൾ തിരയുന്ന പേജ്, കണ്ടെത്താൻ കഴിയില്ല.",
    "Language Selection:": "ഭാഷയുടെ തിരഞ്ഞെടുപ്പ്:",
    "Empowering Effective Communication": "ഫലപ്രദമായ ആശയവിനിമയത്തെ ശക്തിപ്പെടുത്തൽ",
    "Good day! I'm Murthy, your dedicated assistant for today's proceedings. Lets start by selecting the language you're most comfortable with.": "സുഖദിനം! ഞാൻ മൂർത്തി, ഇന്നത്തെ നടപടികൾക്കുള്ള നിങ്ങളുടെ സമർപ്പിത സഹായി. നിങ്ങൾക്ക് ഏറ്റവും അനുയോജ്യമായ ഭാഷ തിരഞ്ഞെടുക്കുന്നതിലൂടെ നമുക്ക് ആരംഭിക്കാം.",
    "Select Your Preferred Language": "നിങ്ങളുടെ ഇഷ്ടഭാഷ തിരഞ്ഞെടുക്കുക",
    "Opting for your preferred language ensures clear and effective communication. Whether it's your native tongue or another language of your choice, selecting it will streamline our interaction and enhance your experience.": "നിങ്ങളുടെ ഇഷ്ടഭാഷ തിരഞ്ഞെടുക്കുന്നതിലൂടെ വ്യക്തവും ഫലപ്രദവുമായ ആശയവിനിമയം ഉറപ്പാക്കാം. അത് നിങ്ങളുടെ മാതൃഭാഷയോ അല്ലെങ്കിൽ നിങ്ങളുടെ തിരഞ്ഞെടുപ്പിലെ മറ്റൊരു ഭാഷയോ ആയിരിക്കാം, അതിനെ തിരഞ്ഞെടുക്കുന്നതിലൂടെ നമ്മുടെ ഇടപെടൽ സുഗമമാക്കുകയും നിങ്ങളുടെ അനുഭവം മെച്ചപ്പെടുത്തുകയും ചെയ്യും.",
    "Next": "അടുത്തത്",
    "Welcome to PrivateCourt, where Helping you is our priority.": "പ്രൈവറ്റ്‌കോർട്ടിലേക്ക് സ്വാഗതം, നിങ്ങളെ സഹായിക്കുക ഞങ്ങളുടെ മുൻഗണനയാണ്.",
    "Now, let me introduce you to PrivateCourt – your reliable partner in resolving lending disputes effectively and efficiently.": "ഇപ്പോൾ, ഞാൻ നിങ്ങളെ പ്രൈവറ്റ്‌കോർട്ടുമായി പരിചയപ്പെടുത്തുന്നു - വായ്പാ തർക്കങ്ങൾ ഫലപ്രദമായി, കാര്യക്ഷമമായി പരിഹരിക്കുന്നതിൽ നിങ്ങളുടെ വിശ്വസ്ത പങ്കാളി.",
    "PrivateCourt": "പ്രൈവറ്റ്‌കോർട്ട്",
    "Your trusted partner in resolving lending disputes with care and expertise.": "വായ്പാ തർക്കങ്ങൾ കരുതലോടും വിദഗ്ധതയോടും കൂടി പരിഹരിക്കുന്നതിൽ നിങ്ങളുടെ വിശ്വസ്ത പങ്കാളി.",
    "Welcome to PrivateCourt, where your case details are in good hands. With the lending company initiating arbitration or conciliation, it's essential for us to understand your situation fully. Please share all the details you can.": "പ്രൈവറ്റ്‌കോർട്ടിലേക്ക് സ്വാഗതം, നിങ്ങളുടെ കേസ് വിശദാംശങ്ങൾ മികച്ച കൈകളിലാണ്. വായ്പാ കമ്പനി മദ്ധ്യസ്ഥത അല്ലെങ്കിൽ പിഴവ് ആരംഭിക്കുന്നതിനാൽ, നിങ്ങളുടെ സ്ഥിതിഗതികൾ പൂർണമായി മനസിലാക്കുന്നത് ഞങ്ങൾക്ക് വളരെ പ്രധാനമാണ്. നിങ്ങൾക്ക് സാധ്യമായ എല്ലാ വിശദാംശങ്ങളും ദയവായി പങ്കിടുക.",
    "The more information we have, the better we can help. Your input is crucial in finding the right solution for you.": "ഞങ്ങൾക്ക് കൂടുതൽ വിവരങ്ങൾ ഉണ്ടെങ്കിൽ, നിങ്ങൾക്ക് മികച്ച സഹായം നൽകാൻ കഴിയുമെന്ന് ഉറപ്പാക്കാം. നിങ്ങളുടെ ഇന്പുട്ട് നിങ്ങൾക്ക് ശരിയായ പരിഹാരം കണ്ടെത്തുന്നതിൽ നിർണായകമാണ്.",
    "Trust us to carefully examine your case and work together to find the best way forward.": "നിങ്ങളുടെ കേസ് സൂക്ഷ്മമായി പരിശോധിക്കാൻ, മുന്നോട്ടുള്ള ഏറ്റവും നല്ല മാർഗം കണ്ടെത്താൻ ഒത്തുചേരാൻ ഞങ്ങളെ വിശ്വസിക്കുക.",
    "Reviewing Your Dispute Information": "നിങ്ങളുടെ തർക്ക വിവരങ്ങൾ അവലോകനം ചെയ്യുന്നു",
    "Let's take a closer look at the details surrounding your dispute. The information we're discussing today has been shared with us by Company, regarding a personal loan.": "നിങ്ങളുടെ തർക്കത്തിന്റെ ചുറ്റുപാടുള്ള വിശദാംശങ്ങളെക്കുറിച്ച് നമുക്ക് കൂടുതൽ അടുത്തുനോക്കാം. ഇന്ന് ഞങ്ങൾ ചർച്ച ചെയ്യുന്ന വിവരങ്ങൾ കമ്പനി ഒരു വ്യക്തിഗത വായ്പയെ സംബന്ധിച്ചാണ് ഞങ്ങളോട് പങ്കുവെച്ചിരിക്കുന്നത്.",
    "By understanding these specifics, we can better assess the situation and formulate a resolution plan that aligns with your circumstances and goals.": "ഈ പ്രത്യേകതകൾ മനസിലാക്കുന്നതിലൂടെ, ഞങ്ങൾ സാഹചര്യങ്ങൾ മെച്ചമായി വിലയിരുത്തുകയും നിങ്ങളുടെ സാഹചര്യങ്ങളോട് യോജിച്ച ഒരു പരിഹാര പദ്ധതി രൂപീകരിക്കുകയും ചെയ്യുന്നു.",
    "Dispute Amount Information": "തർക്കം തുക വിവരങ്ങൾ",
    "According to their records, you initially borrowed an amount of": "അവരുടെ രേഖകളനുസരിച്ച്, നിങ്ങൾ ആദ്യം കടം വാങ്ങിയത്",
    "Presently, your outstanding due stands at Rs.": "പ്രത്യുത്പാദനം അടയ്‌ക്കാനുള്ള തുക ഇപ്പോൾ Rs.",
    "These details provide us with valuable insight into the history and current status of your loan.": "ഈ വിശദാംശങ്ങൾ നിങ്ങളുടെ വായ്പയുടെ ചരിത്രത്തിലും നിലവിലെ നിലയിലും വിലപ്പെട്ട അന്തർവീക്ഷണം നൽകുന്നു.",
    "Spotting Financial Issues: Understanding Unpaid Obligations": "സാമ്പത്തിക പ്രശ്‌നങ്ങൾ കണ്ടെത്തൽ: അടച്ചുപുലർത്താത്ത ബാധ്യതകളെ മനസിലാക്കൽ",
    "Now, let's gently explore the challenges you've faced due to financial constraints. Discussing this may be sensitive, but your openness will greatly assist us in finding the best path forward. Please take a moment to select any of the following options that resonate with your experience:": "ഇപ്പോൾ, സാമ്പത്തിക പ്രതിസന്ധികൾ കാരണം നിങ്ങൾ നേരിട്ട വെല്ലുവിളികളെ നമുക്ക് സൗമ്യമായി അന്വേഷിക്കാം. ഇത് ചർച്ച ചെയ്യുന്നത് സൂക്ഷ്മമായിരിക്കാം, പക്ഷേ നിങ്ങളുടെ തുറന്ന മനസാണ് മികച്ച മാർഗം കണ്ടെത്തുന്നതിന് വലിയ സഹായമാകുന്നത്. നിങ്ങളുടെ അനുഭവങ്ങളുമായി അനുയോജ്യമാണെങ്കിൽ, താഴെ നൽകിയിട്ടുള്ള ഏതെങ്കിലും ഓപ്ഷനുകൾ തിരഞ്ഞെടുക്കാൻ ദയവായി ഒരു നിമിഷം എടുക്കുക:",
    "Financial Loss": "സാമ്പത്തിക നഷ്ടം",
    "Business Loss": "ബിസിനസ് നഷ്ടം",
    "Job Loss": "ജോലി നഷ്ടം",
    "Health Issue": "ആരോഗ്യ പ്രശ്‌നം",
    "Death of the earning member": "ഉപജീവനത്തിൽ നിന്നുള്ള അംഗത്തിന്റെ മരണം",
    "Missing payment details": "പേയ്മെന്റ് വിശദാംശങ്ങൾ കാണ്മാനില്ല",
    "Dispute due to bad behaviour": "മോശമായ പെരുമാറ്റം മൂലമുണ്ടായ തർക്കം",
    "No clear loan information": "വായ്പാ വിവരങ്ങൾ വ്യക്തമല്ല",
    "Death Case": "മരണകേസ്",
    "We kindly request you to upload the death certificate at": "Legal@privatecourt.in ൽ മരണ സർട്ടിഫിക്കറ്റ് അപ്ലോഡ് ചെയ്യാൻ ദയവായി അഭ്യർത്ഥിക്കുന്നു",
    "Other reason/s": "മറ്റ് കാരണങ്ങൾ/കൾ",
    "Your prompt response will greatly assist us in assessing the situation and finding the most suitable resolution. Rest assured, we are committed to working with you to resolve any outstanding issues and ensure a fair and satisfactory outcome.": "നിങ്ങളുടെ വേഗത്തിലുള്ള പ്രതികരണം നമ്മെ സ്ഥിതിഗതികൾ വിലയിരുത്തുകയും ഏറ്റവും അനുയോജ്യമായ പരിഹാരം കണ്ടെത്തുകയും ചെയ്യുന്നതിൽ വലിയ സഹായകമാകും. ഏതെങ്കിലും നിലനിൽക്കുന്ന പ്രശ്നങ്ങൾ പരിഹരിക്കുകയും നീതിയുടെയും സാന്ത്വനവും ഉറപ്പാക്കുന്നതിനായി നിങ്ങളുമായി പ്രവർത്തിക്കാൻ ഞങ്ങൾ പ്രതിജ്ഞാബദ്ധരാണ്.",
    "Partner Dispute": "പങ്കാളി തർക്കം",
    "Funds held with other vendors or clients": "മറ്റ് വിൽപ്പനക്കാരോടോ ക്ലയന്റുകളോടോ ഉള്ള നിധികൾ",
    "Monetary loss through other means": "മറ്റു മാർഗങ്ങളിലൂടെ സാമ്പത്തിക നഷ്ടം",
    "Less than 2 months": "രണ്ട് മാസത്തിൽ കുറവ്",
    "Less than 6 months": "ആറ് മാസത്തിൽ കുറവ്",
    "Less than 1 year": "ഒരു വർഷത്തിൽ കുറവ്",
    "More than 1 year": "ഒരു വർഷത്തിൽ കൂടുതൽ",
    "Exploring Settlement Possibilities": "സമവായ സാധ്യതകൾ കണ്ടെത്തൽ",
    "As our discussion progresses, I appreciate your openness in explaining the reason for the default. It's crucial to address your outstanding dues promptly to prevent any legal complexities. We recommend exploring different avenues, such as seeking assistance from family or investigating alternative financing options, to fulfill your obligations without delay.": "നമ്മുടെ ചർച്ച മുന്നോട്ടു പോകുന്ന കാലത്ത്, ഡീഫോൾട്ടിന്റെ കാരണം വിശദീകരിക്കുന്നതിൽ നിങ്ങളുടെ തുറന്ന മനസ്സിനെ ഞാൻ അഭിനന്ദിക്കുന്നു. ഏത് നിയമ സങ്കീർണ്ണതകളും തടയാൻ തൽസമയം നിങ്ങളുടെ ബാക്കി തുക പരിഹരിക്കുന്നത് നിർണായകമാണ്. നിങ്ങളുടെ ബാധ്യതകൾ വേഗത്തിൽ നിറവേറ്റുന്നതിനായി കുടുംബത്തിന്റെ സഹായം തേടുക അല്ലെങ്കിൽ മറ്റു സാമ്പത്തിക മാർഗങ്ങൾ അന്വേഷിക്കുക പോലുള്ള വിവിധ മാർഗങ്ങൾ പരിശോധിക്കാൻ ഞങ്ങൾ ശുപാർശ ചെയ്യുന്നു.",
    "Timely settlement not only mitigates legal risks but also strengthens trust and credibility in your financial interactions. Your cooperation in this matter is truly valued.": "സമയോചിതമായ സെറ്റിൽമെന്റ് നിയമപൂരിതമായ പ്രശ്നങ്ങൾ കുറയ്ക്കുന്നതിനോടൊപ്പം നിങ്ങളുടെ സാമ്പത്തിക ഇടപെടലുകളിൽ വിശ്വാസവും വിശ്വാസ്യതയും ശക്തിപ്പെടുത്തുന്നു. ഈ വിഷയത്തിൽ നിങ്ങളുടെ സഹകരണം വളരെ വിലമതിക്കുന്നതാണ്.",
    "Settlement Options": "സമവായ ഓപ്ഷനുകൾ",
    "In our efforts to facilitate a swift resolution, we seek your cooperation.": "വേഗത്തിൽ പരിഹാരം കണ്ടെത്തുന്നതിന് ഞങ്ങളുടെ ശ്രമങ്ങളിൽ, നിങ്ങളുടെ സഹകരണം അഭ്യർത്ഥിക്കുന്നു.",
    "Are you open to discussing the settlement of your outstanding dues?": "നിങ്ങളുടെ ബാക്കി തുകയുടെ സെറ്റിൽമെന്റ് ചർച്ച ചെയ്യാൻ നിങ്ങൾ തുറന്ന മനസ്സുള്ളവനാണോ?",
    "Please Select": "ദയവായി തിരഞ്ഞെടുക്കുക",
    "Yes": "അതെ",
    "No": "അല്ല",
    "I am unable to pay by any means": "എനിക്ക് ഏത് രീതിയിലും പണമടയ്ക്കാൻ കഴിയുന്നില്ല",
    "We Appreciate Your Honesty.": "നിങ്ങളുടെ സത്യസന്ധതയെ ഞങ്ങൾ വിലമതിക്കുന്നു.",
    "Aadhaar Verify": "ആധാർ സ്ഥിരീകരിക്കുക",
    "We appreciate your openness to resolving your outstanding dues. To better understand your financial situation and tailor our approach accordingly, could you kindly provide details on your current family income?": "നിങ്ങളുടെ ബാക്കി തുക പരിഹരിക്കുന്നതിൽ നിങ്ങൾ തുറന്ന മനസ്സുള്ളവനാണെന്നതിന് ഞങ്ങൾ നന്ദിയുണ്ട്. നിങ്ങളുടെ സാമ്പത്തിക സാഹചര്യം മെച്ചമായി മനസിലാക്കുകയും അതനുസരിച്ച് നമ്മുടെ സമീപനം ക്രമീകരിക്കുകയും ചെയ്യുന്നതിന്, നിങ്ങളുടെ നിലവിലെ കുടുംബ വരുമാനത്തിന്റെ വിശദാംശങ്ങൾ ദയവായി നൽകാമോ?",
    "No Income": "വരുമാനമില്ല",
    "less than Rs. 10000/- per month": "മാസത്തിൽ 10000/- രൂപയിൽ താഴെ",
    "less than Rs. 20000/- per month": "മാസത്തിൽ 20000/- രൂപയിൽ താഴെ",
    "less than Rs. 40000/- per month": "മാസത്തിൽ 40000/- രൂപയിൽ താഴെ",
    "more than Rs. 100000/- per month": "മാസത്തിൽ 100000/- രൂപയിൽ കൂടുതൽ",
    "In our efforts to address your outstanding dues, we'd like to know if a one-time payment settlement is an option for you.": "നിങ്ങളുടെ ബാക്കി തുക പരിഹരിക്കുന്നതിന് ഞങ്ങളുടെ ശ്രമങ്ങളിൽ, ഒരു തവണ പെയ്മെന്റ് സെറ്റിൽമെന്റ് നിങ്ങളുടെ ഓപ്ഷൻ ആണോ എന്ന് അറിയാൻ ഞങ്ങൾ ആഗ്രഹിക്കുന്നു.",
    "We appreciate your feedback. If a one-time payment is not viable, could you consider arranging payment in three equal instalments?": "നിങ്ങളുടെ പ്രതികരണം ഞങ്ങൾ പ്രശംസിക്കുന്നു. ഒരു തവണ പെയ്മെന്റ് പ്രായോഗികമല്ലെങ്കിൽ, മൂന്നുയോജിച്ചതിൽ സമാനമായതിനെക്കുറിച്ച് പരിഗണിക്കാൻ നിങ്ങൾക്ക് കഴിയുമോ?",
    "This approach could help ease any immediate financial strain while still addressing the outstanding dues.": "ഈ സമീപനം അടിയന്തര സാമ്പത്തിക സമ്മർദ്ദം ഒഴിവാക്കാനും ബാക്കി തുക പരിഹരിക്കാനും സഹായകമാകുന്നു.",
    "Aadhaar Verification": "ആധാർ സ്ഥിരീകരണം",
    "To proceed further, we have initiated the Aadhaar verification process. Please provide the OTP (One-Time Password) that you have received to complete this step. Ensuring the security and authenticity of our users is paramount to us, and this verification step helps us maintain the integrity of our services.": "മുന്നോട്ടുപോകാൻ, ഞങ്ങൾ ആധാർ സ്ഥിരീകരണ പ്രക്രിയ ആരംഭിച്ചു. ഈ ഘട്ടം പൂർത്തിയാക്കുന്നതിന് നിങ്ങൾ സ്വീകരിച്ച OTP (ഒരു തവണ പാസ്വേഡ്) നൽകുക. ഞങ്ങളുടെ ഉപയോക്താക്കളുടെ സുരക്ഷയും വിശ്വാസ്യതയും ഉറപ്പാക്കുന്നത് ഏറ്റവും പ്രധാനമാണ്, ഈ സ്ഥിരീകരണ ഘട്ടം ഞങ്ങളുടെ സേവനങ്ങളുടെ മുഴുനീളം നിലനിർത്താൻ ഞങ്ങളെ സഹായിക്കുന്നു.",
    "Enter the Applicant’s Aadhaar Number": "അപേക്ഷകന്റെ ആധാർ നമ്പർ നൽകുക",
    "Please input the Applicant’s Aadhaar number along with essential demographic details—name, date of birth, and gender. This process ensures accurate cross-checking for security. Thank you for your cooperation.": "അപേക്ഷകന്റെ ആധാർ നമ്പർ, അടിയന്തര ജനസംഖ്യാ വിശദാംശങ്ങൾ—പേര്, ജനന തീയതി, ലിംഗം എന്നിവയും നൽകുക. ഈ പ്രക്രിയ സുരക്ഷയ്ക്കായി കൃത്യമായ ക്രോസ്-ചെക്കിംഗ് ഉറപ്പാക്കുന്നു. നിങ്ങളുടെ സഹകരണത്തിന് നന്ദി.",
    "For any queries or assistance, feel free to call us at +91-9699900111.": "ഏതെങ്കിലും ചോദ്യങ്ങൾക്കോ സഹായത്തിനോ, +91-9699900111 എന്ന നമ്പറിലേക്ക് വിളിക്കാൻ മടിക്കേണ്ടതില്ല.",
    "Thank you for entrusting PrivateCourt. Your security is our priority.": "പ്രൈവറ്റ്‌കോർട്ടിൽ വിശ്വാസം പുലർത്തിയതിന് നന്ദി. നിങ്ങളുടെ സുരക്ഷ ഞങ്ങളുടെ മുൻഗണനയാണ്.",
    "Enter the Aadhaar OTP Here": "ഇവിടെ ആധാർ OTP നൽകുക",
    "Verify OTP": "OTP സ്ഥിരീകരിക്കുക",
    "Thank You Aadhaar Verification Successfully": "ആധാർ സ്ഥിരീകരണം വിജയകരമായി",
    "Thank you for providing the OTP for Aadhaar verification. With this step completed, we are now ready to proceed with your case.": "ആധാർ പരിശോധനയ്ക്ക് OTP നൽകിയത്‌ക്കുള്ള നന്ദി. ഈ ഘട്ടം പൂർത്തിയായതോടെ, നിങ്ങളുടെ കേസ് തുടരാൻ ഞങ്ങൾ സജ്ജമാണ്.",
    "Our team will continue to work diligently to bring about a resolution that meets your needs and expectations. Should you have any questions or concerns along the way, please don't hesitate to reach out to us.": "നിങ്ങളുടെ ആവശ്യം, പ്രതീക്ഷകൾ എന്നിവ നിറവേറ്റുന്ന പരിഹാരം കാണാൻ ഞങ്ങളുടെ ടീം പ്രയത്‌നശീലമായി പ്രവർത്തിക്കുമെന്ന് ഉറപ്പു. വഴിയിലുള്ള ഏതെങ്കിലും ചോദ്യങ്ങൾക്കോ ആശങ്കകൾക്കോ, ഞങ്ങളെ സമീപിക്കാൻ മടിക്കരുത്.",
    "Once again, thank you for entrusting PrivateCourt with your dispute resolution needs. We look forward to serving you.": "വിവാദ പരിഹാര ആവശ്യങ്ങൾക്കായി പ്രൈവറ്റ്‌കോർട്ടിന് വിശ്വാസം നൽകിയത്‌ക്കുറിച്ച് വീണ്ടും നന്ദി. നിങ്ങൾക്ക് സേവനം ചെയ്യാനായി നമുക്ക് മുൻകൂർ.",
    "Warm regards,": "വാത്സല്യപൂർവ്വം,",
    "PrivateCourt Team": "പ്രൈവറ്റ്‌കോർട്ട് ടീം",
    "CONCILIATION NOTICE": "സാധുവാക്കല്‍ അറിയിപ്പ്",
    "Warm Greetings from PRIVATECOURT. We are writing to formally inform you that PRIVATECOURT will oversee and resolve the dispute(s) between you and company .": "PRIVATECOURT-നു നിന്ന് ഹൃദ്യമായ Salam. PRIVATECOURT നിങ്ങളുടെ കമ്പനിയുമായുള്ള തര്‍ക്കങ്ങളെ മേല്‍നോട്ടം വഹിച്ച് പരിഹരിക്കുമെന്ന് ഔദ്യോഗികമായി നിങ്ങളെ അറിയിക്കുകയാണ്.",
    "Please find attached the confirmation document with hearing details that outline our acceptance of our commitment to handling your disputes through Alternative Dispute Resolution (ADR).": "വിവാദങ്ങളെ സാന്ത്വനപരമായ പരിഹാരത്തിനായി (ADR) കൈകാര്യം ചെയ്യുന്നതിനുള്ള പ്രതിജ്ഞയുമായി ഞങ്ങളുടെ അംഗീകരണത്തെ വിശദീകരിക്കുന്ന ഉപദേശം വിശദീകരണമായുള്ള സ്ഥിരീകരണ ഡോക്യുമെന്റ് സംയോജിപ്പിച്ചിരിക്കുന്നു.",
    "To explore resolution options and engage in the process effectively, we provide the following avenues, from which you can select any one way:": "പരിഹാര ഓപ്ഷനുകൾ പരിശോധിക്കുകയും പ്രക്രിയയിൽ ഫലപ്രദമായി ചേരുന്നതിനായി, നിങ്ങൾക്ക് ഏതെങ്കിലും ഒരു മാർഗം തിരഞ്ഞെടുക്കാവുന്ന താഴെപ്പറയുന്ന മാർഗ്ഗങ്ങൾ നാമിൽ നൽകുന്നു:",
    "Access Your Personalized Dispute Resolution Page: Click {{ params.disputelink }} to access your dedicated dispute resolution page. Here, you can provide detailed information about your issue. Our support team will review your submission promptly and guide you through the next steps of the resolution process.": "നിങ്ങളുടെ വ്യക്തിഗത विवाद പരിഹാര പേജ് ആക്സസ് ചെയ്യുക: നിങ്ങളുടെ സമർപ്പിത വിഷയം പരിഹാര പേജിലേക്ക് പ്രവേശിക്കാൻ {{ params.disputelink }} ക്ലിക്ക് ചെയ്യുക. ഇവിടെ, നിങ്ങളുടെ പ്രശ്നത്തെ കുറിച്ച് വിശദമായ വിവരങ്ങൾ നൽകാവുന്നതാണ്. ഞങ്ങളുടെ പിന്തുണ ടീമിന്റെ സമർപ്പണത്തെ ഉടൻ പരിശോധിച്ച്, പരിഹാര പ്രക്രിയയുടെ അടുത്ത പടികളിലൂടെ നിങ്ങളെ വഴികാട്ടും.",
    "OR": "അഥവാ",
    "You are hereby directed to attend the {{params.meetingtype}} hearing scheduled on {{ params.conciiationdate }}, at {{ params.conciliationaddress }}, between {{params.timing}}.": "{{ params.conciiationdate }}-ന് {{ params.conciliationaddress }}-ൽ {{params.meetingtype}} കേട്ടുനിൽക്കാൻ നിങ്ങൾക്ക് ഈ സന്ദേശം വഴി നിർദ്ദേശം ചെയ്യുന്നു, {{params.timing}}-ൽ.",
    "This platform enables you to directly engage with our team, comprehensively discussing and sharing your dispute details.": "ഈ പ്ലാറ്റ്ഫോം നിങ്ങൾക്ക് നേരിട്ട് ഞങ്ങളുടെ ടീമുമായി ബന്ധപ്പെടാൻ, നിങ്ങളുടെ വിവാദ വിശദാംശങ്ങൾ വിശദമായി ചർച്ച ചെയ്യാനും പങ്കുവെക്കാനും സഹായിക്കുന്നു.",
    "If you have any additional information or documentation related to this matter, please feel free to reach out to our dedicated customer support team at +918035731376 or Email: legal@privatecourt.in": "ഈ വിഷയവുമായി ബന്ധപ്പെട്ട കൂടുതൽ വിവരങ്ങൾ അല്ലെങ്കിൽ പ്രമാണങ്ങൾ നിങ്ങൾക്ക് ഉണ്ടെങ്കിൽ, ദയവായി +918035731376-ന് വിളിക്കാനും അല്ലെങ്കിൽ legal@privatecourt.in എന്ന ഇമെയിലിൽ ബന്ധപ്പെടാനും മടിക്കരുത്.",
    "We appreciate your trust in PRIVATECOURT for dispute resolution and are committed to delivering a fair and impartial resolution to the matter at hand.": "വിവാദ പരിഹാരത്തിനായി PRIVATECOURT-ൽ നിങ്ങൾക്കുള്ള വിശ്വാസം ഞങ്ങൾ ആദരിക്കുന്നു, നിങ്ങളുടെ കാര്യത്തിന് ന്യായമായും നീതിയും ചേർന്ന പരിഹാരമൊരുക്കാൻ ഞങ്ങൾ പ്രതിജ്ഞാബദ്ധരാണ്.",
    "Thank You,": "നന്ദി,",
    "Team PrivateCourt": "ടീം പ്രൈവറ്റ്‌കോർട്ട്"
}

export default MalayalamTranslation;