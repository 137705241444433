import React,{useState,useRef} from "react";
import { useSelector, useDispatch } from "react-redux";
import { changeLang } from "../../features/language/langSlice";
import { CompanyList,languageFolders } from "../../Objects/LanguageObject";
const LoginLang = ({ children }) => {
  const theme = useSelector(state => state.theme.theme);
  const companyName=useSelector(state => state.disputeStage?.data?.claimantCompany);
  const language = useSelector(state=>state.lang.lang);
  const disputeStage = useSelector(state => state.disputeStage.disputeStage);
  const dispatch=useDispatch();
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);


  // const CompanyList = {
  //   "Vector Finance Pvt. Ltd.":"vector finance",
  //   "Surjit Hire Purchase Private Limited":"Surjit Hire Purchase",
  //   "STFT Finance Private Limited":"STFT FINANCE",
  //   "":"Smart Prism finance",
  //   "Lendingkart Finance Limited":"LendingKart",
  //   "":"Klubworks Finance",
  //   "":"JS Loans",
  //   "ICICI HOME FINANCE COMPANY LIMITED":"ICICI HOME",
  //   "K. M. GLOBAL CREDIT PRIVATE LIMITED":"Credit Fair Finance",
  //   "Aye Finance Private Limited":"Aye Finance",
  //   "AVANTI FINANCE PRIVATE LIMITED":"AVANTI FINANCE",
  //   "":"Ambit Finance"
  // }
  // const languageFolders = {
  //   "English (UK)": "AudioEnglish",
  //   "Hindi": "AudioHindi",
  //   "Telugu": "AudioTelugu",
  //   "Tamil": "AudioTamil",
  //   "Bengali": "AudioBangla",
  //   "Gujarati":"AudioGujarati",
  //   "Kannada":"AudioKannada",
  //   "Malayalam":"AudioMalayalam",
  //   "Marathi":"AudioMarathi"
  // };
  const LanguageFile = {
    "selectlanguage":"Step1",
    "introduction":"Step2",
    // "disputeReview":"Step3",
    "disputeReview": ["Step3_BeforeName", companyName+"", "Step3_AfterName"],
    "spotreason":"Step4",
    "settlement":"Step5",
    'AdharVerification':"StepAadhaar",
    "verified":"Thanks",
    "server":"NoFound"
  }
  const languageFolder = languageFolders[language] ? languageFolders[language] : languageFolders['English (UK)'];
  const langFile = LanguageFile[disputeStage]
  // const togglePlayPause = () => {
  //   if (audioRef.current.paused) {
  //     audioRef.current.play();
  //     setIsPlaying(true);
  //   } else {
  //     audioRef.current.pause();
  //     setIsPlaying(false);
  //   }
  // };


  const playAudioFiles = (files) => {
    let currentFileIndex = 0;
    const audioElement = audioRef.current;
    console.log(files)

    const playNextFile = () => {
      // while (currentFileIndex < files.length && (files[currentFileIndex] === "" || files[currentFileIndex] == null || files[currentFileIndex] == undefined)) {
      //   currentFileIndex += 1;
      //   console.log()
      // }
       if (currentFileIndex < files.length) {
        console.log(files[currentFileIndex],companyName+"")
        if(files[currentFileIndex] == companyName+""){
          audioElement.src = `https://privatecourtdocs.s3.ap-south-1.amazonaws.com/CompaniesVoice/${CompanyList[companyName+""]}.wav`;
        }
        else{
          audioElement.src = `https://privatecourtdocs.s3.ap-south-1.amazonaws.com/webPageDisputeAudio/${languageFolder}/${files[currentFileIndex]}.wav`;
        }
        audioElement.play();
        currentFileIndex += 1;
      } else {
        setIsPlaying(false); // All files played, update state
      }
    };

    audioElement.onended = playNextFile; // Play next file when the current file ends
    playNextFile(); // Start playing the first file
  }; 


  const togglePlayPause = () => {
    if (audioRef.current.paused) {
      if (disputeStage === 'disputeReview') {
        playAudioFiles(LanguageFile[disputeStage]);
      } else {
        audioRef.current.src = `https://privatecourtdocs.s3.ap-south-1.amazonaws.com/webPageDisputeAudio/${languageFolder}/${langFile}.wav`;
        audioRef.current.play();
      }
      setIsPlaying(true);
    } else {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  };
  
  const handleChangeLang=(language)=>{
    dispatch(changeLang(language));
    // window.location.reload();
  }
    return (
        <div className="language-list">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <ul className="list-inline">       
                <li className="list-inline-item" onClick={() => handleChangeLang("English(UK)")}>English (UK)</li>
                <li className="list-inline-item" id="button1" onClick={() => handleChangeLang("Hindi")}>हिन्दी</li>
                <li className="list-inline-item" id="button2" onClick={() => handleChangeLang("Bengali")}>বাংলা</li>
                <li className="list-inline-item" id="button3" onClick={() => handleChangeLang("Telugu")}>తెలుగు</li>
                <li className="list-inline-item" id="button4" onClick={() => handleChangeLang("Marathi")}>मराठी</li>
                <li className="list-inline-item" id="button5" onClick={() => handleChangeLang("Tamil")}>தமிழ்</li>
                <li className="list-inline-item" id="button6" onClick={() => handleChangeLang("Gujarati")}>ગુજરાતી</li>
                <li className="list-inline-item" id="button7" onClick={() => handleChangeLang("Kannada")}>ಕನ್ನಡ</li>
                <li className="list-inline-item" id="button8" onClick={() => handleChangeLang("Malayalam")}>മലയാളം</li>
                <li className="list-inline-item" id="button9" onClick={() => handleChangeLang("Odia")}>ওড়িয়া</li>
              </ul>         
            </div>
            <div className="col-lg-1">
              <audio id="audioPlayer" ref={audioRef} preload="auto"></audio>
              <button id="toggleButton" onClick={togglePlayPause}>
                <i id="playPauseIcon" className={`bi ${isPlaying ? 'bi-pause-circle' : 'bi-play-circle'}`}></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
};

export default LoginLang;
