import { useDispatch, useSelector } from "react-redux";
import VerifyButton from "../../loginPage/components/verifyButton";
import { changeStage } from '../../features/disputeStage/disputeSlice'
import useLanguage from "../../local/saveLanguage";

const ReviewingPage=()=>{
    const dispatch=useDispatch();
    const translate = useLanguage.getLang();
    const company= useSelector(state => state.disputeStage.data?.claimantCompany);
    const CompanyText = translate["Let's take a closer look at the details surrounding your dispute. The information we're discussing today has been shared with us by Company, regarding a personal loan."] + "";
    const finalText = CompanyText.replace("Company",company+"");
    // console.log(CompanyText,finalText,company)
    const handlesubmit=()=>{
        dispatch(changeStage('spotreason'));
    }
    const data = useSelector(state => state.disputeStage.data);
    // console.log(data);
    return (
        <div class="login-mid">
        <div class="container">
          <div class="row">
            <div class="col-xl-7 col-lg-5 login-mid-left">
              <h2 data-translate="dispute-info-1">{translate['Reviewing Your Dispute Information'] ?? "Reviewing Your Dispute Information"}</h2>
              <p data-translate="dispute-info-2">{finalText ?? `Let's take a closer look at the details surrounding your dispute. The information we're discussing today has been shared with us by ${company}, regarding a personal loan.`}</p>
              <p data-translate="dispute-info-3">{translate["By understanding these specifics, we can better assess the situation and formulate a resolution plan that aligns with your circumstances and goals."] ?? "By understanding these specifics, we can better assess the situation and formulate a resolution plan that aligns with your circumstances and goals."}</p>
            </div>
            <div class="col-xl-5 col-lg-7">
              <div class="card pb-normal bank-verification">
                <div class="vf-content">
                  <h1 data-translate="dispute-info-4">{translate["Dispute Amount Information"] ?? "Dispute Amount Information"}</h1>
                  <p><span data-translate="dispute-info-5">{translate["According to their records, you initially borrowed an amount of"] ?? "According to their records, you initially borrowed an amount of"}</span> <strong>Rs. {data?.totalLoan ? data?.totalLoan : 'N/A'}</strong></p>
                  <p><span data-translate="dispute-info-6">{translate["Presently, your outstanding due stands at Rs."] ?? "Presently, your outstanding due stands at Rs."}</span> <strong>Rs. {data?.claimAmount? data?.claimAmount :'N/A'}</strong></p>
                  <p data-translate="dispute-info-7">{translate["These details provide us with valuable insight into the history and current status of your loan."] ?? "These details provide us with valuable insight into the history and current status of your loan."}</p>
                </div>                  
                <div class="ms-auto">
                <VerifyButton btnName="Next" onSubmit={handlesubmit}/>   
                  {/* <script>
                    function openUrl() {
                      var urlToOpen = '/html/call-discussion/step-4.html';
                      window.open(urlToOpen, '_self');
                    }
                  </script>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}
export default ReviewingPage;