import logo from './logo.svg';
import './App.css';
import { Routes, Route } from "react-router-dom";
import DisputePage from "./DisputePage/DisputePage";
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

function App() {

  const theme = useSelector((state) => state.theme.theme);

  useEffect(() => {
    const importThemeStyle = async () => {
      const styleSheetId = "themeStyleSheet";
      const existingStyleSheet = document.getElementById(styleSheetId);
      if (existingStyleSheet) {
        existingStyleSheet.remove();
      }
      const link = document.createElement("link");
      link.id = styleSheetId;
      link.rel = "stylesheet";
      link.href = theme ? "./style.css" : "./style2.css";
      document.head.appendChild(link);
    };

    importThemeStyle();
  }, [theme]);
  return (
    <>
     <Routes>
        <Route path="/dispute/*" element={<DisputePage />} />
      </Routes>
      </>
  );
}

export default App;
