import { useDispatch,useSelector} from "react-redux";
import VerifyButton from "../../loginPage/components/verifyButton"
import { changeLang } from "../../features/language/langSlice";
import useLanguage from "../../local/saveLanguage";
import { useEffect, useState } from "react";
import { changeStage,getData, insertLocation } from "../../features/disputeStage/disputeSlice";
import axios from 'axios'
const LanguageSelection = () =>{
    const dispatch=useDispatch();
    // const lang=useSelector(state=>state.lang.lang);
    const theme=useSelector(state=>state.theme.theme);
    // const 
    // console.log(theme)
    const translate = useLanguage.getLang();
    // console.log(lang,"lang"); 
    const handleChangeLang=(language)=>{
        // console.log(language)
      dispatch(changeLang(language)); 
      // window.location.reload();
    }
    const handleChangePage=()=>{
      dispatch(changeStage("introduction"));
    }
    useEffect(() => {
      const getLocation = (position) => {
          dispatch(insertLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          }));
      };
  
      const failedToGetLocation = () => {
        dispatch(insertLocation({
          latitude: null,  
          longitude:null
        }));
      };
      navigator.geolocation.getCurrentPosition(getLocation, failedToGetLocation);
    }, []);
    return (
        <div>
       <div class="login-mid">
          <div class="container">
            <div class="row">
              <div class="col-xl-7 col-lg-5 login-mid-left">
                <h2 data-translate="ls-1">{translate ?.["Language Selection:"] ?? "Language Selection:"} {translate ?.["Empowering Effective Communication"] ?? "Empowering Effective Communication"}</h2>
                <p data-translate="ls-2">{translate ?.["Good day! I'm Murthy, your dedicated assistant for today's proceedings. Lets start by selecting the language you're most comfortable with."] ?? "Good day! I'm Murthy, your dedicated assistant for today's proceedings. Let's start by selecting the language you're most comfortable with."}</p>
              </div>
              <div class="col-xl-5 col-lg-7">
                <div class="card pb-normal bank-verification">
                  <div class="vf-content">
                    <h1 data-translate="ls-3">{translate?.['Select Your Preferred Language'] ?? "Select Your Preferred Language"}</h1>
                    <p data-translate="ls-4">{translate?.["Opting for your preferred language ensures clear and effective communication. Whether it's your native tongue or another language of your choice, selecting it will streamline our interaction and enhance your experience."] ?? "Opting for your preferred language ensures clear and effective communication. Whether it's your native tongue or another language of your choice, selecting it will streamline our interaction and enhance your experience."}</p>
                  </div>
                  <select class="form-select" aria-label="Default select example" style={{color: theme ? "#304f9d" : "black"}}
                  onChange={(e) => handleChangeLang(e.target.value)}>
                    <option style={{color: theme ? "#304f9d" : "black"}} selected data-translate="ls-5">{translate?.['Select Your Preferred Language'] ?? "Select Your Preferred Language"}</option>
                    <option style={{color: theme ? "#304f9d" : "black"}} value="English(UK)" >English</option>
                    <option style={{color: theme ? "#304f9d" : "black"}} value="Hindi" >हिन्दी</option>
                    <option style={{color: theme ? "#304f9d" : "black"}} value="Bengali" >বাংলা</option>
                    <option style={{color: theme ? "#304f9d" : "black"}} value="Telugu">తెలుగు</option>
                    <option style={{color: theme ? "#304f9d" : "black"}} value="Marathi" >मराठी</option>
                    <option style={{color: theme ? "#304f9d" : "black"}} value="Tamil" >தமிழ்</option>
                    <option style={{color: theme ? "#304f9d" : "black"}} value="Gujarati" >ગુજરાતી</option>
                    <option style={{color: theme ? "#304f9d" : "black"}} value="Kannada" >ಕನ್ನಡ</option>
                    <option style={{color: theme ? "#304f9d" : "black"}} value="Malayalam" >മലയാളം</option>
                    <option style={{color: theme ? "#304f9d" : "black"}} value="Odia" >ওড়িয়া</option>
                  </select>
                  <div class="ms-auto">
                  <VerifyButton btnName="Verify" onSubmit={handleChangePage} />                 
                  </div>
                </div>
              </div>
          </div>
        </div>
            </div>
        </div>
    )
}
export default LanguageSelection;
