import {createSlice, current} from "@reduxjs/toolkit";

const initialState={
    disputeStage:"selectlanguage",
    data:{},
    accessToken:"",
    typeOfCommunication:"",
    location:{}
}

export const disputeSlice=createSlice({
    name:"disputeStage",
    initialState,
    reducers:{
        changeStage:(state, action)=>{
            state.disputeStage=action.payload;
        },
        getData:(state,action) => {
            state.data=action.payload
        },
        insertToken:(state,action) => {
            state.accessToken=action.payload
        },
        insertLocation:(state,action) => {
            state.location=action.payload
        },
        insertTypeOfCommunication:(state,action) => {
           state.typeOfCommunication=action.payload
        }
    }
});

export const {changeStage,getData,insertToken,insertLocation,insertTypeOfCommunication}=disputeSlice.actions;

export default disputeSlice.reducer;