import React from "react";

const LoginInnerWrapper = ({ children }) => {
    return (
        <div className="login-wrapper">
            {children}
        </div>
    );
};

export default LoginInnerWrapper;