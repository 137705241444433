import {configureStore} from '@reduxjs/toolkit';
import themeReducer from "../features/theme/themeSlice";
import fontReducer from "../features/font/fontSlice";
import langReducer from "../features/language/langSlice"
import disputeStage from "..//features/disputeStage/disputeSlice";

const rootReducer = {
    theme: themeReducer,
    font: fontReducer,
    lang:langReducer,
    disputeStage:disputeStage,
  };

export const store=configureStore({
    reducer:rootReducer
});